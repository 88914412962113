import ReactDOMServer from "react-dom/server"
import { useLocation, useNavigate } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { Tooltip } from "react-tooltip"
import { ProductsActionCreator } from "../../actions/products.action"
import { ContactIconSidebar } from "../../assets/svgs/contactIconside.svg"
import { GraphIconSidebar } from "../../assets/svgs/graphIconSidebar.svg"
import { GridIconSidebar } from "../../assets/svgs/gridIcon.svg"
import { NewLogoutIcon } from "../../assets/svgs/newLogoutIcon.svg"
import { SettingNewIcon } from "../../assets/svgs/settingSideIcon.svg"
import { AdminUserIcon } from "../../assets/svgs/tooltips/adminUserIcon.svg"
import { AccountUserIcon } from "../../assets/svgs/userAccountIcon.svg"
import { VideoIconSidebar } from "../../assets/svgs/videoIconsidebar.svg"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { UtilHelper } from "../../helpers/util.helper"
import { AccountStateSelector } from "../../selectors/accountState.selector"
import { AuthStateSelector } from "../../selectors/authState.selector"
import { PrimaryText } from "../elements/primaryText.element"
import CommonTooltip from "./tooltipItems/commonTooltip.component"

export const SideNavigation = () => {
    const { token, getAccessLevel } = useSelector(AuthStateSelector)
    const { userActionData } = useSelector(AccountStateSelector)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { pathname } = location
    const iconColor = userActionData ? "#9B8AFB" : "#06AED4"
    const handleLogoutClick = () => {
        localStorage.clear()
        window.location.reload()
    }

    console.log("...", getAccessLevel && getAccessLevel.is_staff)

    return (
        <div className="w-[60px] h-full bg-gradient-to-tr from-slate-800 to-slate-700 rounded-2xl justify-start items-start inline-flex">
            <div className="w-[60px] h-full flex-col justify-between items-center inline-flex">
                <div className="p-3 flex-col justify-start items-start gap-3 flex">
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom-end"
                        data-tooltip-offset={-18}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip
                                className="ml-[30px] w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                label="Trackpack Hub"
                            />
                        )}
                        onClick={() => navigate(ROUTES_CONFIG.trackpackCentral)}
                        className={`p-2 cursor-pointer
                        hover:bg-[#475467]
                        
                        ${
                            ROUTES_CONFIG.trackpackCentral === pathname && userActionData
                                ? "bg-gradient-to-br from-[#BDB4FE] to-[#D9D6FE]"
                                : ROUTES_CONFIG.trackpackCentral === pathname && !userActionData
                                ? "bg-gradient-to-br from-cyan-100 to-cyan-200"
                                : ""
                        } rounded-[10px] justify-start items-start gap-2 inline-flex`}
                    >
                        <div className="w-5 h-5 relative flex items-center justify-center ">
                            <GridIconSidebar
                                color={
                                    ROUTES_CONFIG.trackpackCentral === pathname && userActionData
                                        ? "#5925DC"
                                        : ROUTES_CONFIG.trackpackCentral === pathname && !userActionData
                                        ? "#06AED4"
                                        : userActionData
                                        ? "#9B8AFB"
                                        : "#06AED4"
                                }
                            />
                        </div>
                    </div>

                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom-end"
                        data-tooltip-offset={-18}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip
                                className="ml-[30px] w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                label="Performance Tracker"
                            />
                        )}
                        onClick={() => {
                            navigate(ROUTES_CONFIG.dashboard)
                            dispatch(ProductsActionCreator.selectedOptions({ selectedOption: "Graph" }))
                            dispatch(
                                ProductsActionCreator.selectedProducts({
                                    selectedProducts: [],
                                })
                            )
                        }}
                        className={`p-2 cursor-pointer hover:bg-[#475467] ${
                            ROUTES_CONFIG.dashboard === pathname && userActionData
                                ? "bg-gradient-to-br from-[#BDB4FE] to-[#D9D6FE]"
                                : ROUTES_CONFIG.dashboard === pathname && !userActionData
                                ? "bg-gradient-to-br from-cyan-100 to-cyan-200"
                                : ""
                        } rounded-[10px] justify-start items-start gap-2 inline-flex`}
                    >
                        <div className="w-5 h-5 relative flex items-center justify-center">
                            <GraphIconSidebar
                                color={
                                    ROUTES_CONFIG.dashboard === pathname && userActionData
                                        ? "#5925DC"
                                        : ROUTES_CONFIG.dashboard === pathname && !userActionData
                                        ? "#06AED4"
                                        : userActionData
                                        ? "#9B8AFB"
                                        : "#06AED4"
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-col justify-center items-center gap-3 flex">
                    <div className="flex-col justify-center items-center gap-2 flex">
                        {getAccessLevel && getAccessLevel.is_staff && (
                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={-18}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className="ml-[30px] w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="User Accounts"
                                    />
                                )}
                                onClick={() =>
                                    getAccessLevel && getAccessLevel.is_staff && navigate(ROUTES_CONFIG.userAccounts)
                                }
                                className={`p-2 ${
                                    getAccessLevel && getAccessLevel.is_staff ? "cursor-pointer" : "cursor-not-allowed"
                                } hover:bg-[#475467] ${
                                    ROUTES_CONFIG.userAccounts === pathname && userActionData
                                        ? "bg-gradient-to-br from-[#BDB4FE] to-[#D9D6FE]"
                                        : ROUTES_CONFIG.userAccounts === pathname && !userActionData
                                        ? "bg-gradient-to-br from-cyan-100 to-cyan-200"
                                        : ""
                                } rounded-[10px] justify-start items-start gap-2 inline-flex`}
                            >
                                <div className="w-5 h-5 relative flex items-center justify-center">
                                    <AccountUserIcon
                                        color={
                                            ROUTES_CONFIG.userAccounts === pathname && userActionData
                                                ? "#5925DC"
                                                : ROUTES_CONFIG.userAccounts === pathname && !userActionData
                                                ? "#06AED4"
                                                : userActionData
                                                ? "#9B8AFB"
                                                : "#06AED4"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="p-2 cursor-not-allowed rounded-[10px] justify-start items-start gap-2 inline-flex">
                            <div
                                className="w-5 h-5 relative"
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-class-name={"!ml-[35px]"}
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={-6}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className=" w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="Learn More"
                                    />
                                )}
                            >
                                <VideoIconSidebar color={iconColor} />
                            </div>
                        </div>
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={-16}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip
                                    className="ml-[26px] w-auto mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                    label="Help & Support"
                                />
                            )}
                            className="p-2 cursor-not-allowed rounded-[10px] justify-start items-start gap-2 inline-flex"
                        >
                            <div className="w-5 h-5 justify-center items-center flex">
                                <div className="w-5 h-5 relative flex-col justify-start items-start flex">
                                    <ContactIconSidebar color={iconColor} />
                                </div>
                            </div>
                        </div>

                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={-16}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip
                                    className="ml-[26px] w-[100px]  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                    label="Account Settings"
                                />
                            )}
                            className="p-2 cursor-not-allowed rounded-[10px] justify-start items-start gap-2 inline-flex"
                        >
                            <div className="w-5 h-5 justify-center items-center flex">
                                <div className="w-5 h-5 relative flex-col justify-start items-start flex">
                                    <SettingNewIcon color={iconColor} />
                                </div>
                            </div>
                        </div>
                        <div data-tooltip-id={"logout-tooltip"}>
                            <div className="mb-3 cursor-pointer">
                                <div
                                    className={`w-9 h-9 relative rounded-[10px] justify-center items-center flex ${
                                        userActionData ? " bg-[#6938EF]" : "bg-[#475467]"
                                    } `}
                                >
                                    {userActionData ? (
                                        <AdminUserIcon />
                                    ) : (
                                        <div className="w-full font-light text-base leading-6 text-[#EAECF0] h-full relative flex-col justify-center items-center flex border border-transparent ">
                                            {UtilHelper.getInitials(token?.username ?? "User")}
                                        </div>
                                    )}

                                    <Tooltip
                                        id="logout-tooltip"
                                        style={{
                                            padding: "6px 10px",
                                            backgroundColor: "#088AB2",
                                            borderRadius: "6px",
                                            zIndex: 1000,
                                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                        }}
                                        place="right"
                                        delayShow={200}
                                        classNameArrow="custom-arrow light-blue"
                                        openOnClick
                                        clickable
                                        render={() => {
                                            return (
                                                <div>
                                                    <div
                                                        className="flex items-center gap-[6px] cursor-pointer"
                                                        onClick={handleLogoutClick}
                                                    >
                                                        <NewLogoutIcon />
                                                        <PrimaryText
                                                            weight="book"
                                                            size="small"
                                                            className="text-white leading-[20px]"
                                                            onClick={handleLogoutClick}
                                                        >
                                                            Logout
                                                        </PrimaryText>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Tooltip
                id="dashboard-tooltip"
                style={{
                    backgroundColor: "transparent",
                    color: "#222",
                    zIndex: 9000,
                    padding: 0,
                    margin: 0,
                }}
                delayShow={200}
            />
        </div>
    )
}
