export const EyeSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="1.39453" width="15" height="15" rx="2.5" fill="white" />
            <rect x="0.5" y="1.39453" width="15" height="15" rx="2.5" stroke="#D0D5DD" />
            <path
                d="M4.00838 9.19234C3.95164 9.10249 3.92327 9.05757 3.90738 8.98827C3.89546 8.93622 3.89546 8.85414 3.90738 8.80209C3.92327 8.7328 3.95164 8.68787 4.00838 8.59802C4.4773 7.85553 5.87308 5.97852 8.00017 5.97852C10.1273 5.97852 11.523 7.85553 11.992 8.59802C12.0487 8.68787 12.0771 8.7328 12.093 8.80209C12.1049 8.85414 12.1049 8.93622 12.093 8.98827C12.0771 9.05757 12.0487 9.10249 11.992 9.19234C11.523 9.93483 10.1273 11.8118 8.00017 11.8118C5.87308 11.8118 4.4773 9.93483 4.00838 9.19234Z"
                stroke="#667085"
                stroke-width="0.6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.00017 10.1452C8.69052 10.1452 9.25017 9.58554 9.25017 8.89518C9.25017 8.20483 8.69052 7.64518 8.00017 7.64518C7.30981 7.64518 6.75017 8.20483 6.75017 8.89518C6.75017 9.58554 7.30981 10.1452 8.00017 10.1452Z"
                stroke="#667085"
                stroke-width="0.6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>

        // <svg width="16" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <g style={{ mixBlendMode: "multiply" }}>
        //         <rect x="0.5" y="1.39453" width="15" height="15" rx="2.5" fill="#F9FAFB" />
        //         <rect x="0.5" y="1.39453" width="15" height="15" rx="2.5" stroke="#EAECF0" />
        //         <path
        //             d="M4.00838 9.19185C3.95164 9.102 3.92327 9.05708 3.90738 8.98778C3.89546 8.93574 3.89546 8.85365 3.90738 8.8016C3.92327 8.73231 3.95164 8.68739 4.00838 8.59754C4.4773 7.85504 5.87308 5.97803 8.00017 5.97803C10.1273 5.97803 11.523 7.85504 11.992 8.59754C12.0487 8.68739 12.0771 8.73231 12.093 8.8016C12.1049 8.85365 12.1049 8.93574 12.093 8.98778C12.0771 9.05708 12.0487 9.102 11.992 9.19185C11.523 9.93434 10.1273 11.8114 8.00017 11.8114C5.87308 11.8114 4.4773 9.93434 4.00838 9.19185Z"
        //             stroke="#344054"
        //             strokeWidth="0.6"
        //             strokeLinecap="round"
        //             strokeLinejoin="round"
        //         />
        //         <path
        //             d="M8.00017 10.1447C8.69052 10.1447 9.25017 9.58505 9.25017 8.89469C9.25017 8.20434 8.69052 7.64469 8.00017 7.64469C7.30981 7.64469 6.75017 8.20434 6.75017 8.89469C6.75017 9.58505 7.30981 10.1447 8.00017 10.1447Z"
        //             stroke="#344054"
        //             strokeWidth="0.6"
        //             strokeLinecap="round"
        //             strokeLinejoin="round"
        //         />
        //     </g>
        // </svg>
    )
}
