import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { ArchiveIcon } from "../../../../assets/svgs/archiveIcon.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { Root } from "../../../../services/product.service"
import StepIndicator from "../../../common/stepIndicator.component"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"

const ArchiveStepOne: React.FC = () => {
    const dispatch = useDispatch()
    const { selectedTrackpacksList, selectedAsinsLength, userActionData } = useSelector(AccountStateSelector)
    const { archiveState, loading } = useSelector(RunTimeStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const isTrackpackLoading: boolean = useMemo(() => RuntimeHelper.archiveTrackpackLoading(), [loading])
    const isAsinLoading: boolean = useMemo(() => RuntimeHelper.archiveAsinLoading(), [loading])

    const totalSteps = ["one", "two"]
    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeArchiveModal())
    }
    const handleOpenStepTwo = () => {
        if (selectedAsinsLength && selectedAsinsLength?.data > 0) {
            dispatch(
                ProductsActionCreator.archiveAsins(
                    {
                        trackpack_id: selectedTrackpacksList.id,
                        asins: selectedAsinsLength?.selectedRows,
                    },
                    (data: Root) => {
                        // updateDropdownList(data)
                        dispatch(
                            ProductsActionCreator.selectedProducts({
                                selectedProducts: [],
                            })
                        )
                        dispatch(
                            RuntimeActionCreators.openArchiveModal({
                                open: true,
                                stepNumber: "two",
                                isArchiveShow: selectedAsinsLength && selectedAsinsLength?.data > 0 ? true : false,
                            })
                        )
                        if (userActionData !== undefined) {
                            dispatch(
                                ProductsActionCreator.getDropdownList(
                                    {},
                                    { as_user: userActionData && userActionData?.id + "" }
                                )
                            )
                        } else dispatch(ProductsActionCreator.getDropdownList())
                    }
                )
            )
        } else {
            dispatch(
                ProductsActionCreator.archiveTrackpack(
                    {
                        trackpack_id: selectedTrackpacksList.id,
                    },
                    (data: Root) => {
                        // updateDropdownList(data)
                        dispatch(
                            ProductsActionCreator.selectedProducts({
                                selectedProducts: [],
                            })
                        )
                        dispatch(
                            RuntimeActionCreators.openArchiveModal({
                                open: true,
                                stepNumber: "two",
                                isArchiveShow: selectedAsinsLength && selectedAsinsLength?.data > 0 ? true : false,
                            })
                        )
                        if (userActionData !== undefined) {
                            dispatch(
                                ProductsActionCreator.getDropdownList(
                                    {},
                                    { as_user: userActionData && userActionData?.id + "" }
                                )
                            )
                        } else dispatch(ProductsActionCreator.getDropdownList())
                    }
                )
            )
        }
    }

    const updateDropdownList = (updatedData: Root) => {
        const updatedDropdownList = selectedDropdownList?.trackpacks?.map((item: TrackpackDatum) =>
            item.metadata.id === selectedTrackpacksList.id ? updatedData : item
        )
        dispatch(ProductsActionCreator.setDropdownList(updatedDropdownList))
    }

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#FECDCA] rounded-[12px] p-[10px]">
                    <ArchiveIcon />
                </div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText weight="medium" className="text-[24px] text-gray-700 mt-[24px] mb-[16px]">
                Archive. Are you sure?
            </PrimaryText>
            {!archiveState?.isArchiveShow ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline">
                    Looks like you've chosen to Archive your Trackpack called
                    <br />
                    <PrimaryText size="sm-medium" weight="medium" className="inline text-gray-700">
                        {selectedTrackpacksList && selectedTrackpacksList?.title}.
                    </PrimaryText>
                    <span className="font-normal text-gray-700"> Is this right?</span>
                </PrimaryText>
            ) : (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline">
                    Looks like you want to Archive
                    <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700 ">
                        {archiveState?.isArchiveShow && selectedAsinsLength?.data}{" "}
                        {selectedAsinsLength?.data === 1 ? "ASIN" : "ASINs"}
                    </PrimaryText>
                    in{" "}
                    <PrimaryText size="sm-medium" weight="medium" className="inline text-gray-700">
                        {selectedTrackpacksList && selectedTrackpacksList?.title}
                        <br />
                    </PrimaryText>
                    Is this right?
                </PrimaryText>
            )}

            <div className=" mt-[48px]">
                <>
                    <div className="flex justify-center items-center">
                        <StepIndicator currentStep={1} totalSteps={totalSteps} strokeColor="#667085" />
                    </div>
                    <div className="flex gap-2 items-center justify-center mt-[48px]">
                        <div className="flex justify-center items-center ">
                            <ButtonElement
                                onClick={handleOpenStepTwo}
                                size="large"
                                viewType="hover-state"
                                className={
                                    "w-[89px] bg-modal-btn-gradient  rounded-[12px] h-[44px] text-white text-[14px]"
                                }
                                disabled={isAsinLoading || isTrackpackLoading}
                            >
                                Yes
                            </ButtonElement>
                        </div>
                        <div className="flex justify-center items-center">
                            <ButtonElement
                                onClick={handleCloseDialog}
                                size="large"
                                viewType="stay-same"
                                className={
                                    "w-[84px] border  border-[#D0D5DD] bg-[#F9FAFB] rounded-[12px] h-[44px] text-[#667085] text-[14px] flex items-center justify-center "
                                }
                                disabled={isAsinLoading || isTrackpackLoading}
                            >
                                No
                            </ButtonElement>
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

export default ArchiveStepOne
