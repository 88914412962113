import { useTranslation } from "react-i18next"
import NewGiphy from "../../../assets/gifs/trackpack.gif"

import { useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { ReactComponent as AlertIcon } from "../../../assets/svgs/alert-icon.svg"
import { InputIconArrow } from "../../../assets/svgs/inputIcon.svg"
import { useInputValidations } from "../../../hooks/inputValidations.hook"

import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import CeleberationGify from "../../../assets/gifs/celeberationGify.gif"
import { ChatCrossIcon } from "../../../assets/svgs/chatCrossIcon.svg"
import { ChatRefreshIcon } from "../../../assets/svgs/ChatRefreshIcon.svg"
import { TrackPackChatIcon } from "../../../assets/svgs/trackpackchatIcon.svg"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { AuthStateSelector } from "../../../selectors/authState.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { BubbleListToTop, BubblesList } from "../../elements/bubblesList.element"
import { ButtonElement } from "../../elements/button.element"
import { InputElement } from "../../elements/input.element"
import { PrimaryText } from "../../elements/primaryText.element"
import SelectInputElement from "../../elements/selectInput.component"
import { AMAZON_DOMAINS } from "../../marketing/domains.config"
import { TrackPackChatWrapper } from "./trackPackChatWrapper.component"

const TrackPackChatModal = () => {
    const dispatch = useDispatch()
    const listRef = useRef<any>(null)
    const notifyError = (text: string) => toast.error(text)
    const { getAccessLevel } = useSelector(AuthStateSelector)

    const [step, setStep] = useState<IStepTrackPack>({})
    const [data, setData] = useState<IStepTrackPackDataOptions>({})
    const [modal, setDataModal] = useState<IConversationItem[]>([])
    const [step6Data, setStep6Data] = useState<IConversationItem[]>([])
    const [renderSequence, setRenderSequence] = useState<string[]>([])
    const [repeatCount, setRepeatCount] = useState(0)
    const [multiAsins, setMultiAsins] = useState<string[]>([])
    const [yesNoOptions, setYesNoOptions] = useState<string[]>([])
    const [inputOptions, setInputOptions] = useState<string[]>([])
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.createTrackpackLoading(), [loading])

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm()
    const { t } = useTranslation("marketing", {
        keyPrefix: "modalTrackPackChat.conversation",
    })

    const { step2, step3, step4, step5, step6, step7, step8, step9 } = data
    const inputValue = watch("newAsin")
    const amazon_Id = watch("amazon_Id")
    const validationConfig = useInputValidations()

    const yesNo = repeatCount % 2 === 0 && repeatCount > 0
    const IsInput = repeatCount % 2 !== 0 && repeatCount > 0

    const loginUserName = getAccessLevel?.first_name
        ? getAccessLevel?.first_name.charAt(0)?.toUpperCase() + getAccessLevel?.first_name.slice(1)
        : getAccessLevel && getAccessLevel?.username?.charAt(0)?.toUpperCase() + getAccessLevel?.username?.slice(1)

    const stepOneData = `${`Hey ${loginUserName}! <br/><br/>  Thanks for starting your Free Trial. I’m <span class='text-gray-700 font-[500] lg:text-[1rem] md:text-[1rem] sm:text-[0.875rem] xs:text-[0.875rem]'>Am<span class='text-[#06AED4] underline pb-[2px]'>ai</span>zing</span>, your personal <br/>AI-driven Amazon Consultant. <br/><br/> I never sleep. Like, never ever...  <br/> <br/> The analysis you can get done in a week, I can do in minutes…`}`
    const lastStepData = `${`Wahoo! Great work ${loginUserName}. <br/><br/> I’m busy getting all the data for your first Trackpack, which will be <br/> ready to view in the <b class='font-[500]'>Performance Tracker</b> soon. <br /><br /> Alternatively, you can view it in your <b class='font-[500]'>Trackpack Hub</b> now <br /> where you can make any changes to it.`}`
    const dataSet: IConversationItem[] = useMemo(() => {
        let data: IConversationItem[] = [
            {
                text: stepOneData,
                isIcon: true,
            },
            {
                src: NewGiphy,
                srcType: "custom",
                className: "w-[240px] h-[148px] ml-[62px] object-cover ",
            },

            {
                text: t("2"),
                isIcon: true,
            },
            {
                text: t("3"),
                isIcon: true,
            },
        ]
        step.step1 &&
            data.push({ text: amazon_Id?.label, trackType: "sender" }, { text: t("9"), isIcon: true, className: "" })
        step.step2 && data.push({ text: step2, trackType: "sender" }, { text: t("4"), isIcon: true })
        step.step3 && data.push({ text: step3, trackType: "sender" }, { text: t("7"), isIcon: true, className: "" })
        step.step4 &&
            data.push(
                { text: step4, trackType: "sender" },
                { text: t("11"), isIcon: true, className: "" },
                { text: t("19"), isIcon: true }
            )
        step.step5 && data.push({ text: step5, trackType: "sender" })
        step.step6 &&
            data.push(
                { text: step6, trackType: "sender" },
                { text: t("13"), isIcon: true },
                { text: t("14"), isIcon: true },
                { text: t("15"), isIcon: true },
                { text: t("16"), isIcon: true }
            )
        step.step7 && data.push({ text: step7, trackType: "sender" }, { text: t("10"), isIcon: true })
        step.step8 && data.push({ text: step8, trackType: "sender" }, { text: t("10"), isIcon: true })
        step.step9 &&
            data.push(
                { text: step9, trackType: "sender" },
                {
                    src: CeleberationGify,
                    srcType: "custom",
                    className: "w-[240px] h-[148px] ml-[62px]",
                },
                { text: lastStepData, isIcon: true }
            )

        return data
    }, [step])

    const handleChange = (stepKey: keyof IStepTrackPack, action: "next" | "previous", value?: string) => {
        if (action === "next") {
            setStep((prev: IStepTrackPack) => ({
                ...prev,
                [stepKey]: true,
            }))
            setData((prevData: IStepTrackPackDataOptions) => ({
                ...prevData,
                [stepKey]: value || inputValue,
            }))
            setValue("newAsin", "")
        }
    }

    const currentStep = Object.entries(step).filter(([key, value]) => value).length

    const handleButtonClick = (action: "next" | "previous", value: string) => {
        if (
            (currentStep === 2 || currentStep === 5 || currentStep === 3 || currentStep === 6) &&
            action === "next" &&
            value === "No"
        ) {
            setStep((prevStep: IStepTrackPack) => ({
                ...prevStep,
                step3: true,
                step4: true,
                step5: true,
                step6: true,
                step7: true,
            }))
            setData((prevData: IStepTrackPackDataOptions) => ({
                ...prevData,
                step7: "No",
            }))
            setRepeatCount(0)
            handleChange(`step7` as keyof IStepTrackPack, "next", "No")
        } else if (currentStep === 6 && action === "next" && value === "Yes") {
            const newEntry = [{ text: inputValue, trackType: "sender" }, dataSet[12]]
            setDataModal(newEntry)
            setRepeatCount(repeatCount + 1)
        } else if (currentStep === 9 && action === "next" && value === "Yes") {
            return
        } else if (currentStep === 5 && action === "next" && value === "Yes") {
            setStep6Data([
                { text: "Yes", trackType: "sender" },
                { text: t("11"), isIcon: true, className: "" },
            ])
            handleChange(`step${currentStep + (action === "next" ? 1 : 0)}` as keyof IStepTrackPack, action, value)
            setRepeatCount(repeatCount + 1)
        } else {
            handleChange(`step${currentStep + (action === "next" ? 1 : 0)}` as keyof IStepTrackPack, action, value)
        }
    }

    const renderSteps = () => {
        const steps = []
        if (step.step5 || step.step6) {
            steps.push(
                <div key="step-5-6" className="mb-4">
                    <BubbleListToTop>
                        <BubblesList interval={500} maxSleep={500} onFinish={() => {}} conversationItems={modal} />
                    </BubbleListToTop>
                </div>
            )
        }

        return steps
    }

    const renderSteps2 = () => {
        const steps = []
        if (step.step6 || step.step7) {
            steps.push(
                <div key="step-6-7" className="mb-4">
                    <BubbleListToTop>
                        <BubblesList interval={500} maxSleep={500} onFinish={() => {}} conversationItems={step6Data} />
                    </BubbleListToTop>
                </div>
            )
        }

        return steps
    }
    const handleRenderSteps = () => {
        setRenderSequence((prevSequence) => [...prevSequence, "yesNo"])
        setYesNoOptions([...yesNoOptions, "yes"])
    }

    const handleRenderSteps2 = () => {
        setRenderSequence((prevSequence) => [...prevSequence, "isInput"])
        setRepeatCount(repeatCount + 1)
        setValue("newAsin", "")
        setInputOptions([...inputOptions, inputValue])
    }

    const onSubmit = () => {
        if (currentStep === 4 || currentStep === 6) {
            const newEntry = [{ text: inputValue, trackType: "sender" }, dataSet[12]]
            setDataModal(newEntry)
        }

        if (step6 && IsInput) {
            handleRenderSteps2()
        }

        if (currentStep === 7) {
            setMultiAsins([...multiAsins, inputValue])
        }
        if ([0, 1, 4, 7, 8].includes(currentStep)) {
            if (currentStep === 7) {
                return setValue("newAsin", "")
            } else {
                handleChange(`step${currentStep + 1}` as keyof IStepTrackPack, "next")
            }
        }
    }

    const handleCloseDialog = (value?: string) => {
        setStep({})
        setData({})
        setDataModal([])
        setStep6Data([])
        setMultiAsins([])
        setRenderSequence([])
        setRepeatCount(0)
        setValue("amazon_Id", "")
        if (value) {
            return
        } else {
            dispatch(RuntimeActionCreators.closeTrackpackModal())
        }
    }

    const handleScrollToView = () => {
        setTimeout(() => {
            listRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
        }, 600)
    }

    const handleRemoveAsin = (index: number) => {
        const updated = multiAsins?.filter((val) => {
            return val !== multiAsins[index]
        })
        setMultiAsins(updated)
    }

    const removeYourAsins = (index: number, value: number | string) => {
        const updatedList = inputOptions?.filter((val) => {
            return val !== inputOptions[index]
        })
        setInputOptions(updatedList)
    }

    const handleRemoveStep = (step: keyof IStepTrackPackDataOptions) => {
        setData((prevData) => {
            const newData = { ...prevData }
            delete newData[step]
            return newData
        })
    }

    const handelChatSubmit = () => {
        const payload = {
            own_asins: [data.step2, data.step5, ...inputOptions].filter(Boolean),
            name: data?.step9,
            amazon_tld: amazon_Id?.value,
            competitor_asins: multiAsins,
        }
        dispatch(
            ProductsActionCreator.createTrackpackAccount(
                { ...payload },
                () => {
                    handleCloseDialog && handleCloseDialog()
                    dispatch(ProductsActionCreator.getDropdownList())
                },
                (res: { message: string }) => {
                    notifyError(res.message)
                }
            )
        )
    }

    return (
        <>
            <TrackPackChatWrapper
                className={`absolute top-[40px] left-[50%] -translate-x-2/4 bg-white rounded-[12px] m-auto w-[800px] shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
            >
                <div
                    className="px-[20px] gap-[16px] overflow-auto trackpack-modal-scrollbar pt-[20px]"
                    style={{ width: "calc(100% - 20px)", height: "calc(100vh - 350px)" }}
                >
                    <div className="pr-[13px]">
                        <div className={""}>
                            <BubbleListToTop>
                                <BubblesList
                                    modal={true}
                                    interval={500}
                                    maxSleep={500}
                                    onFinish={() => {}}
                                    conversationItems={[...dataSet]}
                                />
                            </BubbleListToTop>
                            {step.step1 && (
                                <BubbleListToTop>
                                    <BubblesList
                                        modal={true}
                                        interval={500}
                                        maxSleep={500}
                                        onFinish={() => {}}
                                        className=""
                                        conversationItems={[dataSet[4], dataSet[5]]}
                                    />
                                </BubbleListToTop>
                            )}

                            {step.step2 && (
                                <BubbleListToTop>
                                    <BubblesList
                                        modal={true}
                                        interval={500}
                                        maxSleep={500}
                                        onFinish={() => {}}
                                        conversationItems={[dataSet[6], dataSet[7]]}
                                    />
                                </BubbleListToTop>
                            )}
                            {step.step3 && step3 === "Yes" && (
                                <BubbleListToTop>
                                    <BubblesList
                                        modal={true}
                                        interval={500}
                                        maxSleep={500}
                                        onFinish={() => {}}
                                        conversationItems={[dataSet[8], dataSet[9]]}
                                    />
                                </BubbleListToTop>
                            )}

                            {step4 && step3 === "Yes" && (
                                <BubbleListToTop>
                                    <BubblesList
                                        modal={true}
                                        interval={500}
                                        maxSleep={500}
                                        onFinish={() => {}}
                                        conversationItems={[dataSet[10], dataSet[11]]}
                                    />
                                </BubbleListToTop>
                            )}

                            {step.step5 &&
                                modal?.map((item: IConversationItem, index: number) => (
                                    <BubbleListToTop key={index}>
                                        <BubblesList
                                            modal={true}
                                            interval={500}
                                            maxSleep={500}
                                            onFinish={() => {}}
                                            conversationItems={[item]}
                                        />
                                    </BubbleListToTop>
                                ))}
                            {step.step6 &&
                                step6Data?.map((item: IConversationItem, index: number) => (
                                    <BubbleListToTop key={index}>
                                        <BubblesList
                                            modal={true}
                                            interval={500}
                                            maxSleep={500}
                                            onFinish={() => {}}
                                            conversationItems={[item]}
                                        />
                                    </BubbleListToTop>
                                ))}

                            {renderSequence.map((funcName, index) => (
                                <div key={index}>
                                    {funcName === "isInput" && renderSteps()}
                                    {funcName === "yesNo" && renderSteps2()}
                                </div>
                            ))}
                            {step7 && (
                                <>
                                    <BubbleListToTop>
                                        <BubblesList
                                            modal={true}
                                            interval={500}
                                            maxSleep={500}
                                            onFinish={() => {}}
                                            conversationItems={[dataSet[19], dataSet[20]]}
                                        />
                                    </BubbleListToTop>

                                    {multiAsins.map((value, index) => (
                                        <BubbleListToTop key={index}>
                                            <BubblesList
                                                modal={true}
                                                interval={500}
                                                maxSleep={500}
                                                onFinish={() => {}}
                                                conversationItems={[
                                                    {
                                                        className: `${index !== 0 ? "mr-[40px]" : ""}`,
                                                        text: value,
                                                        ...(index === 0 && { trackType: "sender" }),
                                                        isFirstMultiAsin: true,
                                                    },
                                                ]}
                                            />
                                        </BubbleListToTop>
                                    ))}
                                </>
                            )}

                            {step8 && (
                                <>
                                    <div className="flex gap-2" ref={listRef}>
                                        <TrackPackChatIcon />
                                        <ul className=" rounded-xl bg-gray-100 p-[6px] text-[#344054]">
                                            <PrimaryText
                                                size="sm-medium"
                                                weight="medium"
                                                className="text-[#06AED4] mb-[6px] mx-[6px]"
                                            >
                                                Your Asins
                                            </PrimaryText>

                                            {data?.step2 && (
                                                <li className="flex items-center gap-[4px] mb-[4px] bg-[#FFF] py-[2px] px-[6px] rounded-[6px] text-[14px] font-[300] text-gray-700 w-fit">
                                                    <PrimaryText
                                                        weight="light"
                                                        size="small"
                                                        className="text-gray-700 uppercase min-w-[96px]"
                                                    >
                                                        {data?.step2}
                                                    </PrimaryText>

                                                    <ChatCrossIcon onClick={() => handleRemoveStep("step2")} />
                                                </li>
                                            )}
                                            {data?.step5 && (
                                                <li className="flex items-center gap-[4px] mb-[4px] bg-[#FFF] py-[2px] px-[6px] rounded-[6px] text-[14px] font-[300] text-gray-700 w-fit">
                                                    <PrimaryText
                                                        weight="light"
                                                        size="small"
                                                        className="text-gray-700 uppercase min-w-[96px]"
                                                    >
                                                        {data?.step5}
                                                    </PrimaryText>

                                                    <ChatCrossIcon onClick={() => handleRemoveStep("step5")} />
                                                </li>
                                            )}
                                            {inputOptions?.length > 0 &&
                                                inputOptions?.map((value, index) => {
                                                    return (
                                                        <li
                                                            className="flex items-center gap-[4px] mb-[4px] bg-[#FFF] py-[2px] px-[6px] rounded-[6px] text-[14px] font-[300] text-gray-700 w-fit"
                                                            key={index}
                                                        >
                                                            <PrimaryText
                                                                weight="light"
                                                                size="small"
                                                                className="text-gray-700 uppercase min-w-[96px]"
                                                            >
                                                                {value}
                                                            </PrimaryText>
                                                            <ChatCrossIcon
                                                                onClick={() => removeYourAsins(index, value)}
                                                            />
                                                        </li>
                                                    )
                                                })}
                                        </ul>
                                    </div>
                                </>
                            )}
                            {step8 && (
                                <>
                                    <div className="flex gap-2 mt-3" ref={listRef}>
                                        <TrackPackChatIcon />
                                        <ul className=" rounded-xl bg-gray-100 p-[6px] text-[#344054]">
                                            <PrimaryText
                                                size="sm-medium"
                                                weight="medium"
                                                className="text-[#06AED4] mb-[6px] mx-[6px]"
                                            >
                                                Competitors
                                            </PrimaryText>
                                            {multiAsins?.map((value, index) => {
                                                return (
                                                    <li
                                                        className="mb-[4px] bg-[#FFF] py-[2px] px-[6px] rounded-[6px] text-[14px] font-[300] text-gray-700 w-fit flex items-center gap-[4px]"
                                                        key={index}
                                                    >
                                                        <PrimaryText
                                                            weight="light"
                                                            size="small"
                                                            className="text-gray-700 uppercase min-w-[96px]"
                                                        >
                                                            {value}
                                                        </PrimaryText>

                                                        <ChatCrossIcon onClick={() => handleRemoveAsin(index)} />
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="flex gap-2 mt-3" ref={listRef}>
                                        <TrackPackChatIcon />
                                        <PrimaryText
                                            weight="light"
                                            size="sm-medium"
                                            className="h-[36px] flex items-center py-[6px] px-[12px] rounded-[12px] bg-gray-100 text-gray-700 leading-[24px]"
                                        >
                                            Last step - let's give your Trackpack name...
                                        </PrimaryText>
                                    </div>
                                </>
                            )}

                            {step9 && (
                                <BubbleListToTop>
                                    <BubblesList
                                        modal={true}
                                        interval={500}
                                        maxSleep={500}
                                        onFinish={() => {}}
                                        conversationItems={[dataSet[23], dataSet[24], dataSet[25]]}
                                    />
                                </BubbleListToTop>
                            )}
                        </div>
                    </div>
                </div>

                {![0, 1, 4, 7, 8].includes(currentStep) ? (
                    <>
                        {repeatCount === 0 && (
                            <div className="flex items-center gap-3 justify-center bg-gray-200 pt-[32px] pb-[40px] rounded-bl-[10px] rounded-br-[10px] mt-[16px]">
                                {currentStep === 9 ? (
                                    <div className="flex gap-2">
                                        <ButtonElement
                                            size="medium"
                                            textClass="font-[500]"
                                            className="bg-gradient-to-r from-[#475467] to-[#667085] w-[268px] h-[44px] rounded-[8px] text-white text-[16px] font-[500] leading-[24px] hover:bg-gradient-to-r hover:from-gray-800 hover:to-gray-600 active:bg-gradient-to-r active:from-gray-800 active:to-gray-600 focus:bg-gradient-to-r focus:from-gray-800 focus:to-gray-600"
                                            onClick={() => {
                                                if (currentStep === 9) {
                                                    handelChatSubmit()
                                                }
                                            }}
                                            disabled={isLoading}
                                            loading={isLoading}
                                            viewType={isLoading ? "trackpack-loading-button" : "primary"}
                                        >
                                            {"View Trackpack Now"}
                                        </ButtonElement>
                                    </div>
                                ) : (
                                    <>
                                        <ButtonElement
                                            size="medium"
                                            textClass="font-[500]"
                                            className="bg-gradient-to-r from-[#475467] to-[#667085] w-[128px] h-[44px] rounded-[8px] text-white text-[16px] font-[500] leading-[24px] hover:bg-gradient-to-r hover:from-gray-800 hover:to-gray-600 active:bg-gradient-to-r active:from-gray-800 active:to-gray-600 focus:bg-gradient-to-r focus:from-gray-800 focus:to-gray-600 "
                                            onClick={() => {
                                                handleButtonClick("next", "Yes")
                                                if (step6) {
                                                    handleRenderSteps()
                                                }
                                            }}
                                        >
                                            {"Yes"}
                                        </ButtonElement>
                                    </>
                                )}
                                {currentStep !== 9 && (
                                    <ButtonElement
                                        size="medium"
                                        className="bg-white w-[128px] h-[44px] rounded-[8px] text-gray-500 border border-gray-200 !text-[16px] font-[500] leading-[24px] hover:bg-gray-50"
                                        viewType="button-text"
                                        textClass="text-[16px] font-[500]"
                                        onClick={() => {
                                            handleButtonClick("next", "No")
                                        }}
                                    >
                                        {"No"}
                                    </ButtonElement>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="bg-gray-200 pt-[32px] pb-[40px] px-[82px] rounded-bl-[10px] rounded-br-[10px] mt-[16px]"
                    >
                        <>
                            {currentStep === 0 ? (
                                <div className="flex gap-2 items-center">
                                    <div className="w-full">
                                        <SelectInputElement
                                            name="amazon_Id"
                                            reactHookControl={control}
                                            reactHookValidations={{ required: true }}
                                            defaultValue={null}
                                            countries={AMAZON_DOMAINS}
                                            placeholder="Choose market..."
                                            isOptionImage={true}
                                            isInputImage={true}
                                            errorMessage="Please choose your market to continue"
                                        />
                                    </div>
                                    <ButtonElement
                                        disabled={!amazon_Id?.value}
                                        type="submit"
                                        size="medium"
                                        textClass="font-[500] text-[16px]"
                                        className="bg-gradient-to-r from-[#475467] to-[#667085] w-[128px] h-[44px] rounded-[8px] text-white text-[16px] font-[500] leading-[24px] hover:bg-gradient-to-r hover:from-gray-800 hover:to-gray-600 active:bg-gradient-to-r active:from-gray-800 active:to-gray-600 focus:bg-gradient-to-r focus:from-gray-800 focus:to-gray-600"
                                        onClick={() => {
                                            handleButtonClick("next", "Yes")
                                        }}
                                    >
                                        Confirm
                                    </ButtonElement>
                                </div>
                            ) : (
                                <div className="flex gap-2 items-start w-full">
                                    {repeatCount === 0 && (
                                        <InputElement
                                            name="newAsin"
                                            type="text"
                                            reactHookControl={control}
                                            reactHookValidations={
                                                currentStep === 8
                                                    ? {
                                                          required: validationConfig.optionalAsin.message,
                                                          pattern: validationConfig.optionalAsin,
                                                      }
                                                    : {
                                                          required: validationConfig.newAsin.message,
                                                          pattern: validationConfig.newAsin,
                                                      }
                                            }
                                            postfix={
                                                <button type="submit" className="pt-2">
                                                    {errors && errors?.newAsin?.message ? (
                                                        <AlertIcon />
                                                    ) : (
                                                        <InputIconArrow />
                                                    )}
                                                </button>
                                            }
                                            className="rounded-[12px] w-full h-[44px] m-0"
                                        />
                                    )}
                                    {currentStep === 7 && (
                                        <ButtonElement
                                            disabled={inputValue}
                                            size="medium"
                                            textClass="font-[500] text-[16px]"
                                            className="bg-gradient-to-r from-[#475467] to-[#667085] w-[128px] h-[44px] rounded-[8px] text-white text-[16px] font-[500] leading-[24px] hover:bg-gradient-to-r hover:from-gray-800 hover:to-gray-600 active:bg-gradient-to-r active:from-gray-800 active:to-gray-600 focus:bg-gradient-to-r focus:from-gray-800 focus:to-gray-600 "
                                            onClick={() => {
                                                handleScrollToView()
                                                handleButtonClick("next", "Yes")
                                            }}
                                        >
                                            {"Finish"}
                                        </ButtonElement>
                                    )}
                                </div>
                            )}
                        </>
                    </form>
                )}
                {yesNo && (
                    <div className="flex items-center gap-3 justify-center bg-gray-200 pt-[32px] pb-[40px] rounded-bl-[10px] rounded-br-[10px] mt-[16px]">
                        <ButtonElement
                            size="medium"
                            textClass="font-[500] text-[16px]"
                            className="bg-gradient-to-r from-[#475467] to-[#667085] w-[128px] h-[44px] rounded-[8px] text-white text-[16px] font-[500] leading-[24px] hover:bg-gradient-to-r hover:from-gray-800 hover:to-gray-600 active:bg-gradient-to-r active:from-gray-800 active:to-gray-600 focus:bg-gradient-to-r focus:from-gray-800 focus:to-gray-600"
                            onClick={() => {
                                handleButtonClick("next", "Yes")
                                if (step5) {
                                    handleRenderSteps()
                                }
                            }}
                        >
                            {"Yes"}
                        </ButtonElement>
                        <ButtonElement
                            size="medium"
                            textClass="font-500 text-[16px]"
                            className="bg-white w-[128px] h-[44px] rounded-[8px] text-gray-500 border border-gray-200 text-[16px] font-[500] leading-[24px] hover:bg-gray-50"
                            viewType="button-text"
                            onClick={() => {
                                handleButtonClick("next", "No")
                            }}
                        >
                            {"No"}
                        </ButtonElement>
                    </div>
                )}
                {IsInput && (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="bg-gray-200 pt-[32px] pb-[40px] px-[82px] rounded-bl-[10px] rounded-br-[10px] mt-[16px]"
                    >
                        <InputElement
                            name="newAsin"
                            type="text"
                            reactHookControl={control}
                            reactHookValidations={{
                                required: validationConfig.newAsin.message,
                                pattern: validationConfig.newAsin,
                            }}
                            postfix={
                                <button type="submit" className="pt-2">
                                    {errors && errors?.newAsin?.message ? <AlertIcon /> : <InputIconArrow />}
                                </button>
                            }
                            className="rounded-[12px]"
                        />
                    </form>
                )}
                <div className="absolute mt-[24px] left-[50%] translate-x-[-50%] flex items-center gap-[4px]">
                    <PrimaryText
                        weight="light"
                        size="xs"
                        onClick={() => {
                            handleCloseDialog("reset")
                        }}
                        className=" text-gray-400 z-10 "
                    >
                        Want to start the chat again?&nbsp;
                        <b className=" text-gray-400 z-10 cursor-pointer font-[500] text-[12px]">Click here</b>
                    </PrimaryText>
                    <ChatRefreshIcon />
                </div>
            </TrackPackChatWrapper>
        </>
    )
}

export default TrackPackChatModal
