import { useState } from "react"

import { PrimaryText } from "../elements/primaryText.element"

export const NotificationCard = (props: INotificationCard) => {
    const [menuIconClicked, setMenuIconClicked] = useState(false)
    const handleMenuIconClicked = () => {
        props.onClick && props.onClick()
        setMenuIconClicked(!menuIconClicked)
    }
    return (
        <div className={"relative flex gap-[12px] pb-[12px] w-full"}>
            <img src={props.src} alt={props.altText} className="h-[48px] w-[48px]" />
            <div
                className="w-full">
                <div className={"flex gap-[8px]"}>
                    <PrimaryText size={'xs-medium'}  weight={"medium"} className="leading-[18px] mb-[2px]">
                        {props.alertText}
                    </PrimaryText>
                    <PrimaryText size={'xs-medium'}  weight={"light"} className="text-[#98A2B3]">
                        {props.time}
                    </PrimaryText>
                </div>
                <div className="absolute right-0 top-0 h-[10px] w-[10px] bg-[#F04438] rounded-full mr-[5px]"></div>
                {/* <div>
                    <PrimaryText size={"small"} weight={"light"} className={"text-gray-700"}>
                        {props.description}
                    </PrimaryText>
                </div> */}
                <div className={"flex gap-[12px] w-full justify-between pt-[0px] "}>
                    <PrimaryText size='small' weight="medium" className="text-cyan-500">
                        {props.itemName}
                    </PrimaryText>
                    <img
                        src={props.showDetailIcon}
                        alt={props.altText}
                        onClick={handleMenuIconClicked}
                        className={`cursor-pointer mr-[24px] ${menuIconClicked ? "rotate-90" : ""}`}
                    />
                </div>
            </div>
        </div>
    )
}
