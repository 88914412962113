import { store } from "../store"

export class RuntimeHelper {
    ////////////////////////////////////////////////////////////////////////////////// PUBLIC USER
    /**
     * Check if ForgotPassword loading
     */
    static isAlphaEmailVerification(): boolean {
        return RuntimeHelper.isLoading("alphaEmailVerificationHandler")
    }
    static isAlphaUserRegister(): boolean {
        return RuntimeHelper.isLoading("alphaUserRegisterHandler")
    }
    static isObtainToken(): boolean {
        return RuntimeHelper.isLoading("getTokenSagaHandler")
    }
    static productLoading(): boolean {
        return RuntimeHelper.isLoading("getProductsByGroupHandler")
    }
    static trackpackLoading(): boolean {
        return RuntimeHelper.isLoading("getDropdownHandler")
    }
    static createTrackpackLoading(): boolean {
        return RuntimeHelper.isLoading("createAccountSagaHandler")
    }
    static archiveTrackpackLoading(): boolean {
        return RuntimeHelper.isLoading("archiveSelectedTrackpackHandler")
    }
    static archiveAsinLoading(): boolean {
        return RuntimeHelper.isLoading("archiveSelectedAsinsHandler")
    }
    static addAdditionalAsinLoading(): boolean {
        return RuntimeHelper.isLoading("createAdditionalAccountSagaHandler")
    }
    ////////////////////////////////////////////////////////////////////////////////// PUBLIC USER

    /**
     * Get current state of loading array
     * We are also not looking here if they need preloader or now, value basically
     * We just see if it exists in array
     *
     * @private
     */
    static isLoading(key: string): boolean {
        const currentlyLoading = (store.getState().runTime as IRuntimeState).loading

        return !!currentlyLoading.find((item) => Object.keys(item).includes(key))
    }
}
