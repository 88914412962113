import { useState } from "react"

import ChargerItem from "../../assets/svgs/asin-charger.svg"
import GoldenMobile from "../../assets/svgs/asin-golden-mobile.svg"
import AsinMobile from "../../assets/svgs/asin-mobile.svg"
import { ReactComponent as CheckIcon } from "../../assets/svgs/check.svg"
import RightChevron from "../../assets/svgs/chevron-right-black.svg"
import { ReactComponent as CloseNotifications } from "../../assets/svgs/close-notification.svg"
import { ReactComponent as Menus } from "../../assets/svgs/menus.svg"
import WirelessCharger from "../../assets/svgs/wireless-charger.svg"
import { NotificationCard } from "../common/notificationCard.component"
import { PrimaryText } from "../elements/primaryText.element"

export const NotificationAlert = (props: INotificationCard) => {
    const [showNextItems, setShowNextItems] = useState(false)
    const [showMarkAllRead, setShowMarkAllRead] = useState(false)

    const toggleNextItems = () => {
        setShowNextItems((prev) => !prev)
    }
    const toggleMarkAllRead = () => {
        setShowMarkAllRead((prev) => !prev)
    }
    return (
        <div
            className={"w-[400px] bg-white right-0 absolute p-[24px] rounded-md border-l-gray-200 top-[40px]"}
            style={{
                zIndex: "1000",
                // height:'calc(100vh - 108px)',
                borderRadius: "16px",
                boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
                height: "472px",
                maxHeight: "472px",
                overflow: "auto",
            }}
        >
            <div className={"flex justify-between pb-[26px]"}>
                <PrimaryText size={"xl"} weight={"medium"}>
                    Notifications
                </PrimaryText>
                <div className={"flex gap-[12px]"}>
                    <Menus onClick={toggleMarkAllRead} className={"cursor-pointer"} />
                    <CloseNotifications onClick={props.onClose} className={"cursor-pointer"} />
                </div>
            </div>
            {showMarkAllRead && (
                <div className={" mt-[-30px] flex justify-center ml-[140px]"}>
                    <div
                        className={
                            "flex gap-[8px] px-[8px] py-[10px] items-center rounded-md lg:max-w-[144px] lg:max-h-[44px] border border-gray-200 shadow-md"
                        }
                    >
                        <PrimaryText size={"small"} weight={"light"} className={"text-gray-900"}>
                            Mark as all read
                        </PrimaryText>
                        <CheckIcon />
                    </div>
                </div>
            )}

            <div className={"max-h-[58px] md:h-full"}>
                <NotificationCard
                    src={ChargerItem}
                    alertText={"1 change made in group"}
                    time={"25 mins ago"}
                    itemName={"3-in-1 Wireless Chargers"}
                    showDetailIcon={RightChevron}
                />
                <div className="w-full text-slate-700 pb-[12px]">
                    <hr />
                </div>
                <NotificationCard
                    src={WirelessCharger}
                    alertText={"3 changes made in group"}
                    time={"1 day ago"}
                    itemName={"Cell Phones Wireless Chargers"}
                    showDetailIcon={RightChevron}
                    onClick={toggleNextItems}
                />
                {showNextItems && (
                    <>
                        <div className={"pl-[60px]"}>
                            <NotificationCard
                                src={GoldenMobile}
                                alertText={"Image change"}
                                time={"1 day ago"}
                                itemName={"ASIN ABCDEFGH"}
                            />
                            <NotificationCard
                                src={AsinMobile}
                                alertText={"Price change"}
                                time={"1 day ago"}
                                itemName={"ASIN IJKLMNOPQ"}
                            />
                            <NotificationCard
                                src={WirelessCharger}
                                alertText={"Title change"}
                                time={"1 day ago"}
                                itemName={"ASIN RSTUVWXYZ"}
                            />
                        </div>
                        <div className="w-full text-gray-700 pb-[12px]">
                            <hr />
                        </div>
                    </>
                )}
                <div className={"pb-[12px] relative"}>
                    <div className="w-full">
                        <div className={"flex gap-[8px]"}>
                            <PrimaryText weight={"medium"} className={"text-[10px]"}>
                                Subscription
                            </PrimaryText>
                            <PrimaryText weight={"light"} className={"text-[10px]"}>
                                25 mins ago
                            </PrimaryText>
                        </div>
                        <div className="absolute right-0 top-0 h-[10px] w-[10px] bg-[#F04438] rounded-full mr-[5px]"></div>
                    </div>

                    <PrimaryText size={"small"} weight={"light"}>
                        Your subscription is nearly due for renewal.
                        <br />
                        <span className={"text-sm font-bold leading--[20px] text-cyan-500"}> Renew now.</span>
                    </PrimaryText>
                </div>
                <div className="w-full text-gray-700 pb-[12px]">
                    <hr />
                </div>
                <div className={"pb-[12px]"}>
                    <div className="w-full relative">
                        <div className={"flex gap-[8px]"}>
                            <PrimaryText weight={"medium"} className={"text-[10px]"}>
                                ASIN Capacity
                            </PrimaryText>
                            <PrimaryText weight={"light"} className={"text-[10px]"}>
                                25 mins ago
                            </PrimaryText>
                        </div>
                        <div className="absolute right-0 top-0 h-[10px] w-[10px] bg-[#F04438] rounded-full mr-[5px]"></div>
                    </div>
                    <PrimaryText size={"small"} weight={"light"}>
                        You’re tracking <span className="font-medium">41 / 50</span> available ASINs. Need more?
                        <br />
                        <span className={"text-sm font-medium text-cyan-500"}> Upgrade plan</span> now.
                    </PrimaryText>
                </div>
                <div className="w-full text-gray-700 pb-[12px]">
                    <hr />
                </div>
            </div>
        </div>
    )
}
