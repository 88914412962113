import { ProductsAction } from "../actions/products.action"
import { ProductStateClass } from "../classes/productState.class"

//Get Data from guess account
export const ProductReducer = (
    state: IProductState = new ProductStateClass(), //initial state set through class
    action: ISagaAction
): Partial<IProductState> => {
    switch (action.type) {
        case ProductsAction.STORE_PRODUCTS:
            return {
                ...state,
                selectedGroupProducts: action.payload,
            }

        case ProductsAction.SELECT_PRODUCTS_VIEW:
            return {
                ...state,
                selectedProductView: action.payload,
            }
        case ProductsAction.SELECT_PRODUCTS_RESULTS:
            return {
                ...state,
                selectedProductResult: action.payload,
            }
        case ProductsAction.SELECT_PRODUCTS:
            return {
                ...state,
                selectedProducts: action.payload,
            }
        case ProductsAction.SELECT_LOG_PRODUCT:
            return {
                ...state,
                selectedLogProduct: action.payload,
            }
        case ProductsAction.SELECT_PRODUCTS_RANGE:
            return {
                ...state,
                selectedProductRange: action.payload,
            }
        case ProductsAction.SELECT_OPTION:
            return {
                ...state,
                selectedOption: action.payload,
            }
        case ProductsAction.CREATE_TRACKPACK:
            return {
                ...state,
                selectedTrackpackPayload: {
                    ...state.selectedTrackpackPayload,
                    ...action.payload,
                },
            }
        case ProductsAction.CREATE_TRACKPACK_ACCOUNT: {
            return {
                ...state,
            }
        }
        case ProductsAction.CREATE_ADDITIONAL_TRACKPACK_ACCOUNT: {
            return {
                ...state,
            }
        }
        case ProductsAction.GET_TRACKPACKS_DROPDOWN_LIST:
            return {
                ...state,
                selectedDropdownListParams: action.payload,
            }
        case ProductsAction.SET_TRACKPACKS_DROPDOWN_LIST:
            return {
                ...state,
                selectedDropdownList: action.payload,
            }
        case ProductsAction.UPDATE_TRACK_NAME:
            return {
                ...state,
            }
        case ProductsAction.ARCHIVE_TRACKPACK:
            return {
                ...state,
            }
        case ProductsAction.ARCHIVE_ASINS:
            return {
                ...state,
            }
        default:
            return state
    }
}
