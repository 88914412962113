export const LinkExternalSvg = (props: ILinkExternalSvgProps) => {
    return (
        <>
            <svg
                className={props.className}
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.75 4.14441L8.75 1.64441M8.75 1.64441H6.25M8.75 1.64441L5.41667 4.97774M4.16667 2.47774H3.25C2.54993 2.47774 2.1999 2.47774 1.93251 2.61398C1.69731 2.73383 1.50608 2.92505 1.38624 3.16025C1.25 3.42764 1.25 3.77768 1.25 4.47774V7.14441C1.25 7.84447 1.25 8.19451 1.38624 8.4619C1.50608 8.6971 1.69731 8.88833 1.93251 9.00817C2.1999 9.14441 2.54993 9.14441 3.25 9.14441H5.91667C6.61673 9.14441 6.96677 9.14441 7.23415 9.00817C7.46936 8.88833 7.66058 8.6971 7.78042 8.4619C7.91667 8.19451 7.91667 7.84447 7.91667 7.14441V6.22774"
                    stroke={props.color}
                    stroke-width="0.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
