export const ChatRefreshIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.6353 8.59536C13.4501 10.3353 12.4643 11.9658 10.833 12.9076C8.12263 14.4724 4.65695 13.5438 3.09214 10.8335L2.92547 10.5448M2.36411 7.40467C2.54931 5.66474 3.53517 4.03426 5.16649 3.09241C7.87682 1.5276 11.3425 2.45623 12.9073 5.16655L13.074 5.45523M2.3288 12.044L2.81683 10.2227L4.6382 10.7107M11.3616 5.28934L13.183 5.77737L13.671 3.95601"
                stroke="#98A2B3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
