import { all, fork, put } from "redux-saga/effects"
import { call, takeLatest } from "typed-redux-saga"

import { ProductsAction, ProductsActionCreator } from "../actions/products.action"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { ProductService } from "../services/product.service"

/////////////////////////// -- Get Guest Account -- ///////////////////////////
function* getProductsByGroup() {
    yield takeLatest(ProductsAction.GET_PRODUCTS_BY_GROUP, getProductsByGroupHandler)
}

function* getProductsByGroupHandler<T extends ISagaAction & { payload: { trackpackId: string; amazaon_tld: string } }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getProductsByGroupHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(
            ProductService.getProductByGroupId,
            action.payload.trackpackId,
            action.payload.amazaon_tld,
            pathParams,
            queryParams
        )
        if (result) {
            yield put(ProductsActionCreator.storeProducts(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getProductsByGroupHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getDropdownList() {
    yield takeLatest(ProductsAction.GET_TRACKPACKS_DROPDOWN_LIST, getDropdownHandler)
}

function* getDropdownHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getDropdownHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(ProductService.getAllDrowndownList, pathParams, queryParams)

        if (result) {
            yield put(ProductsActionCreator.setDropdownList(result as any))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getDropdownHandler"))
        action.onFinally && action.onFinally()
    }
}

function* createAccount() {
    yield takeLatest(ProductsAction.CREATE_TRACKPACK_ACCOUNT, createAccountSagaHandler)
}

function* createAccountSagaHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.TrackpackaccountCreation, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

function* createAddtionalTrackpackAccount() {
    yield takeLatest(ProductsAction.CREATE_ADDITIONAL_TRACKPACK_ACCOUNT, createAdditionalAccountSagaHandler)
}

function* createAdditionalAccountSagaHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createAdditionalAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.additionalTrackpackaccountCreation, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createAdditionalAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End Get Guest Account -- ///////////////////////////

function* updateName() {
    yield takeLatest(ProductsAction.UPDATE_TRACK_NAME, updateSelectedTrackpackName)
}

function* updateSelectedTrackpackName<
    T extends ISagaAction & {
        payload: { id: string; name: string }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        // yield put(RuntimeActionCreators.startLoading("updateGuestAccountSagaHandler", !action.ignorePreloader))

        const { id, name } = action.payload
        const result = yield* call(ProductService.updateTrackpackName, id, { name })

        if (result) {
            // yield put(AuthActionCreator.storeGuest(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        // yield put(RuntimeActionCreators.stopLoading("patchGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////////  Archive trackpack /////////////////////////////

function* archiveTrackpack() {
    yield takeLatest(ProductsAction.ARCHIVE_TRACKPACK, archiveSelectedTrackpackHandler)
}

function* archiveSelectedTrackpackHandler<
    T extends ISagaAction & {
        payload: { id: number }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("archiveSelectedTrackpackHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.archiveTrakpack, action.payload)

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("archiveSelectedTrackpackHandler"))
        action.onFinally && action.onFinally()
    }
}

/////////////////////////////  Archive asins /////////////////////////////

function* archiveAsins() {
    yield takeLatest(ProductsAction.ARCHIVE_ASINS, archiveSelectedAsinsHandler)
}

function* archiveSelectedAsinsHandler<
    T extends ISagaAction & {
        payload: { id: number }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("archiveSelectedAsinsHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.archiveAsins, action.payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("archiveSelectedAsinsHandler"))

        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([
        fork(getProductsByGroup),
        fork(createAccount),
        fork(createAddtionalTrackpackAccount),
        fork(getDropdownList),
        fork(updateName),
        fork(archiveAsins),
        fork(archiveTrackpack),
    ])
}
