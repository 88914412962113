import { combineReducers } from "redux"

// App Reducers
import { AccountsReducers } from "./account.reducer"
import { AuthReducer } from "./auth.reducer"
import { OnBoardingUserReducer } from "./onBoarding.reducer"
import { ProductReducer } from "./product.reducer"
import { RunTimeReducer } from "./runTime.reducer"

export const rootReducer = combineReducers({
    auth: AuthReducer,
    product: ProductReducer,
    runTime: RunTimeReducer,
    onBoarding: OnBoardingUserReducer,
    accounts: AccountsReducers,
})
export type RootState = ReturnType<typeof rootReducer>
