export const InputIconArrow = (props: IArrowProps) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.33301 7.99967H12.6663M12.6663 7.99967L7.99967 3.33301M12.6663 7.99967L7.99967 12.6663"
                stroke="#98A2B3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
