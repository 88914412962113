import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ExpandIcon } from "../../../assets/svgs/expandIcon.svg"
import { BulletsIconTooltip } from "../../../assets/svgs/tooltips/popups/tooltip-bullets.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import Slider from "../../elements/slider.component"
import { StringDiffElement } from "../../elements/stringDiff.element"

export const TooltipBullets = (props: ITooltipData) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("tooltip", {
        keyPrefix: "bullets",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })
    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )
    const [activeDiff, setActiveDiff] = useState<"before" | "after" | "change">("change")
    const { active, history, setActive, total } = useProductHistory(props.data, "bullets")

    const handleOpen = (index: number) => {
        dispatch(
            RuntimeActionCreators.openModal({
                color: props?.color,
                open: true,
                type: "bullets",
                data: props?.data,
                activeDiff: activeDiff,
                index: index,
            })
        )
    }

    function getUniqueValues(pastArray: string[] | undefined, presentArray: string[] | undefined) {
        let uniqueInPast = history?.past?.value
        let uniqueInPresent = history?.present?.value

        if (presentArray && pastArray) {
            let uniqueInPast: string[] | undefined = pastArray.filter(
                (pastItem, index) => pastItem !== presentArray[index]
            )
            let uniqueInPresent: string[] | undefined = presentArray.filter(
                (presentItem, index) => presentItem !== pastArray[index]
            )

            if (props?.data?.bullets?.length > 1) {
                const maxLength = Math.max(uniqueInPast?.length, uniqueInPresent.length)
                const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(""))
                const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(""))
                return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
            }
        }
        return { uniqueInPast, uniqueInPresent }
    }

    const { uniqueInPast, uniqueInPresent } = getUniqueValues(history.past?.value, history.present?.value)

    return (
        <>
            <div className="w-[316px]  py-[12px] bg-[#1D2939] rounded-2xl pr-0">
                <div className="px-[12px]">
                    <InnerTooltipHeader
                        title={t("Bullets")}
                        linkText={tCommon("Go to ASIN")}
                        timestamp={tCommon("timestamp", {
                            timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                        })}
                        active={active}
                        total={total}
                        setActive={setActive}
                        color={props.color?.light}
                        id={props.id}
                        icon={<BulletsIconTooltip stroke={props.color?.hover} />}
                    />
                </div>
                {uniqueInPast && (
                    <div className="w-[102px] h-[16px] overflow-hidden rounded-[8px]  border border-gray-600 justify-center items-center inline-flex mt-[32px] ml-[12px]">
                        <div
                            onClick={() => setActiveDiff("before")}
                            className={`cursor-pointer ${
                                activeDiff === "before"
                                    ? "bg-gray-600 text-white h-full w-[28px]"
                                    : "text-gray-500 h-[16px] w-[28px]"
                            }  justify-center rounded-l-[8px] items-center flex`}
                        >
                            <PrimaryText
                                size={"xs-small"}
                                weight={"book"}
                                className=" pt-[2px] pl-[3px] whitespace-nowrap flex items-center justify-center"
                            >
                                {tCommon("was")}
                            </PrimaryText>
                        </div>

                        <div
                            onClick={() => setActiveDiff("change")}
                            className={`cursor-pointer ${
                                activeDiff === "change"
                                    ? "bg-gray-600   text-white h-full w-[47px]"
                                    : "text-gray-500 border-l border-r border-[#475467] h-full w-[47px] "
                            }  justify-center items-center gap-1.5 flex`}
                        >
                            <PrimaryText
                                size={"xs-small"}
                                weight={"book"}
                                className=" flex justify-center whitespace-nowrap pt-[2px]"
                            >
                                {tCommon("Changes")}
                            </PrimaryText>
                        </div>

                        <div
                            onClick={() => setActiveDiff("after")}
                            className={`rounded-r-[8px] cursor-pointer ${
                                activeDiff === "after"
                                    ? "bg-gray-600 h-full w-[29px] text-white flex items-center justify-center"
                                    : "text-gray-500  h-full  w-[29px] "
                            }  justify-center items-center flex`}
                        >
                            <PrimaryText
                                size={"xs-small"}
                                weight={"book"}
                                className=" pt-[2px] pr-[2px] flex justify-center whitespace-nowrap"
                            >
                                {tCommon("now")}
                            </PrimaryText>
                        </div>
                    </div>
                )}

                <div className={uniqueInPast ? "mt-[8px]" : "mt-[32px]"}>
                    <Slider color={props.color}>
                        {uniqueInPast && activeDiff === "before"
                            ? uniqueInPast
                                  .map((bullet: string, index: number) => ({
                                      past: bullet,
                                      present: uniqueInPresent[index],
                                      index,
                                  }))
                                  .filter(({ past, present }: { past: string; present: string }) => past !== present)
                                  .map(({ past, index }: { past: string; index: number }) => (
                                      <div
                                          key={index}
                                          className="embla__slide overflow-hidden min-w-[244px] h-[168px] px-2 pt-2 pb-3 bg-slate-600 rounded-lg flex-col justify-start items-start gap-2 inline-flex mr-[12px]"
                                      >
                                          <div className="flex items-center justify-between w-full">
                                              <div className="w-3 h-3 bg-slate-700 rounded-[3px] flex-col justify-center items-center flex">
                                                  <PrimaryText
                                                      className="text-center text-white leading-[6px] pt-[2px]"
                                                      size="xs-small"
                                                      weight="medium"
                                                  >
                                                      {index + 1}
                                                  </PrimaryText>
                                              </div>
                                              <ExpandIcon
                                                  onClick={() => handleOpen(index)}
                                                  className="cursor-pointer"
                                                  color={props?.color?.light}
                                              />
                                          </div>

                                          <div className="w-[228px] overflow-y-auto overflow-x-hidden scrollbar-pr flex-col justify-start items-start gap-2.5 flex">
                                              <div className="justify-start items-start pr-[6px] gap-2.5 inline-flex">
                                                  <PrimaryText
                                                      className="text-white leading-[16px]"
                                                      size="xs"
                                                      weight="light"
                                                  >
                                                      {past}
                                                  </PrimaryText>
                                              </div>
                                          </div>
                                      </div>
                                  ))
                            : activeDiff === "change" && uniqueInPast && uniqueInPresent
                            ? uniqueInPast
                                  .map((bullet: string, index: number) => ({
                                      past: bullet,
                                      present: uniqueInPresent && uniqueInPresent[index],
                                      index,
                                  }))
                                  .filter(({ past, present }: { past: string; present: string }) => past !== present)
                                  .map(({ past, present, index }: { past: string; present: string; index: number }) => (
                                      <div key={index} className="embla__slide w-[244px] mr-[12px]">
                                          <div className="h-[168px] p-[8px] bg-slate-600 rounded-lg flex flex-col">
                                              <div className="flex items-center justify-between w-full">
                                                  <div className="bg-slate-800 rounded-[3px] h-[12px] w-[12px] flex items-center justify-center">
                                                      <PrimaryText
                                                          className="text-white text-center h-full align-middle leading-[7px] w-full flex items-center justify-center pr-[1px] pt-[1px]"
                                                          size="xs-small"
                                                          weight="bold"
                                                      >
                                                          {index + 1}
                                                      </PrimaryText>
                                                  </div>
                                                  <ExpandIcon
                                                      onClick={() => handleOpen(index)}
                                                      className="cursor-pointer"
                                                      color={props?.color?.light}
                                                  />
                                              </div>
                                              <div className="flex-1 max-h-[228px] overflow-auto mt-[8px] text-[12px] leading-[16px] font-[300] pr-[6px] scrollbar-pr">
                                                  <StringDiffElement pastValue={past} presentValue={present} />
                                              </div>
                                          </div>
                                      </div>
                                  ))
                            : activeDiff === "after" && uniqueInPast && uniqueInPresent
                            ? uniqueInPresent
                                  .map((bullet: string, index: number) => ({
                                      past: uniqueInPast && uniqueInPast[index],
                                      present: bullet,
                                      index,
                                  }))
                                  //   .filter(({ past, present }: { past: string; present: string }) => past !== present)
                                  .map(({ past, present, index }: { past: string; present: string; index: number }) => (
                                      <div
                                          key={index}
                                          className="embla__slide overflow-hidden w-[244px] h-[168px] px-2 pt-2 pb-3 bg-slate-600 rounded-lg flex-col justify-start items-start gap-2 inline-flex mr-[12px]"
                                      >
                                          <div className="flex items-center justify-between w-full">
                                              <div className="w-3 h-3 bg-slate-700 rounded-[3px] flex-col justify-center items-center flex">
                                                  <PrimaryText
                                                      className="text-center text-white leading-[6px] pt-[2px]"
                                                      size="xs-small"
                                                      weight="bold"
                                                  >
                                                      {index + 1}
                                                  </PrimaryText>
                                              </div>
                                              <ExpandIcon
                                                  onClick={() => handleOpen(index)}
                                                  className="cursor-pointer"
                                                  color={props?.color?.light}
                                              />
                                          </div>
                                          <div className="w-[228px] overflow-y-auto overflow-x-hidden scrollbar-pr flex-col justify-start items-start gap-2.5 flex">
                                              <div className="justify-start items-start pr-[6px] gap-2.5 inline-flex">
                                                  <PrimaryText
                                                      className="text-white leading-[16px]"
                                                      size="xs"
                                                      weight="light"
                                                  >
                                                      {present}
                                                  </PrimaryText>
                                              </div>
                                          </div>
                                      </div>
                                  ))
                            : uniqueInPresent
                                  .map((bullet: string, index: number) => ({
                                      past: history.past && uniqueInPast[index],
                                      present: bullet,
                                      index,
                                  }))
                                  //   .filter(({ past, present }: { past: string; present: string }) => past !== present)
                                  .map(({ past, present, index }: { past: string; present: string; index: number }) => (
                                      <div
                                          key={index}
                                          className="embla__slide overflow-hidden w-[244px] h-[168px] px-2 pt-2 pb-3 bg-slate-600 rounded-lg flex-col justify-start items-start gap-2 inline-flex mr-[12px]"
                                      >
                                          <div className="justify-between items-center flex w-full">
                                              <div className="w-3 h-3 bg-slate-700 rounded-[3px] flex-col justify-center items-center flex">
                                                  <PrimaryText
                                                      className="text-center text-white leading-[6px] pt-[2px]"
                                                      size="xs-small"
                                                      weight="bold"
                                                  >
                                                      {index + 1}
                                                  </PrimaryText>
                                              </div>
                                              <ExpandIcon
                                                  onClick={() => handleOpen(index)}
                                                  className="cursor-pointer"
                                                  color={props?.color?.light}
                                              />
                                          </div>
                                          <div className="w-[228px] overflow-y-auto overflow-x-hidden scrollbar-pr flex-col justify-start items-start gap-2.5 flex">
                                              <div className="justify-start items-start pr-[6px] gap-2.5 inline-flex">
                                                  <PrimaryText
                                                      className="text-white leading-[14px]"
                                                      size="xs"
                                                      weight="light"
                                                  >
                                                      {present}
                                                  </PrimaryText>
                                              </div>
                                          </div>
                                      </div>
                                  ))}
                    </Slider>
                </div>
                {/* {isSuccessWarning && (
                    <div className="w-full px-[12px]  pt-[8px]">
                        <ErrorTooltipAlert />
                    </div>
                )}

                {isWarning && (
                    <div className="w-full  px-[12px]  pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
            {/* dialog  */}
        </>
    )
}
