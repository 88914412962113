import { useNavigate } from "react-router-dom"

import AmaizingLogo from "../assets/svgs/new-logo.svg"

export const Header = () => {
    const navigate = useNavigate()
    return (
        <div className="h-[80px]">
            <div className="flex justify-between flex-row items-center py-[24px] px-[26px]">
                <div>
                    <img className="" src={AmaizingLogo} alt="amaizing-logo for header" />
                </div>
                {/* <div>
                    <button
                        onClick={() => navigate(ROUTES_CONFIG.welcome)}
                        type="submit"
                        className={
                            "w-full font-[500] leading-[24px] text-[#0E7090] bg-gradient-to-br from-[#CFF9FE] to-[#A5F0FC] rounded-[8px] text-[16px] px-[17px] py-[8px]"
                        }
                    >
                        Log in
                    </button>
                </div> */}
            </div>
        </div>
    )
}
