import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { StringDiffElement } from "../../elements/stringDiff.element"

import { twMerge } from "tailwind-merge"
import { TitleIconTooltip } from "../../../assets/svgs/tooltips/popups/title.svg"

export const TooltipTitle = (props: ITooltipData) => {
    const { active, history, setActive, total } = useProductHistory(props.data, "title")
    const { t } = useTranslation("tooltip", {
        keyPrefix: "title",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })
    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )

    const [activeDiff, setActiveDiff] = useState<"before" | "after" | "change">("change")

    return (
        <div className={`w-[216px] p-[12px] bg-[#1D2939] rounded-2xl`}>
            <div className={`w-full`}>
                <InnerTooltipHeader
                    title={t("Title")}
                    linkText={tCommon("Go to ASIN")}
                    timestamp={tCommon("timestamp", {
                        timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                    active={active}
                    total={total}
                    setActive={setActive}
                    color={props.color?.light}
                    id={props.id}
                    icon={<TitleIconTooltip stroke={props.color?.hover} />}
                />
            </div>

            {history.past && (
                <div className="w-[102px] h-[16px] overflow-hidden whitespace-nowrap rounded-[8px]  border border-gray-600 justify-center items-center inline-flex mt-[32px]">
                    <div
                        onClick={() => setActiveDiff("before")}
                        className={` cursor-pointer ${
                            activeDiff === "before"
                                ? "bg-gray-600 text-white h-full w-[28px]"
                                : "text-gray-500 h-full w-[28px]"
                        } justify-center rounded-l-[8px] items-center flex`}
                    >
                        <PrimaryText
                            size={"xs-small"}
                            weight={"book"}
                            className="pt-[2px] pl-[0px] whitespace-nowrap flex items-center justify-center"
                        >
                            {tCommon("was")}
                        </PrimaryText>
                    </div>

                    <div
                        onClick={() => setActiveDiff("change")}
                        className={`cursor-pointer ${
                            activeDiff === "change"
                                ? "bg-gray-600   text-white h-full w-[47px]"
                                : "text-gray-500 border-l border-r border-[#475467] h-full w-[47px]"
                        } justify-center items-center flex`}
                    >
                        <PrimaryText
                            size={"xs-small"}
                            weight={"book"}
                            className="flex justify-center whitespace-nowrap pt-[2px]"
                        >
                            {tCommon("Changes")}
                        </PrimaryText>
                    </div>

                    <div
                        onClick={() => setActiveDiff("after")}
                        className={`rounded-r-[8px] cursor-pointer  ${
                            activeDiff === "after"
                                ? "bg-gray-600 h-full w-[29px] text-white flex items-center justify-center"
                                : "text-gray-500  h-full  w-[29px]"
                        } justify-center items-center flex`}
                    >
                        <PrimaryText
                            size={"xs-small"}
                            weight={"book"}
                            className="pt-[2px] pr-[2px] flex justify-center whitespace-nowrap"
                        >
                            {tCommon("now")}
                        </PrimaryText>
                    </div>
                </div>
            )}

            <div
                className={twMerge("w-full p-[8px] bg-slate-600 rounded-lg", history.past ? "mt-[12px]" : "mt-[32px]")}
            >
                <PrimaryText size={"xs"} weight={"light"} color="text-white" className="leading-[18px]">
                    {activeDiff === "before" ? (
                        history.past?.value
                    ) : activeDiff === "change" && history.past && history.present ? (
                        <StringDiffElement pastValue={history.past.value} presentValue={history.present.value} />
                    ) : (
                        history.present?.value
                    )}
                </PrimaryText>
            </div>
            {/* {isSuccessWarning && (
                <div className="w-full  pt-[8px]">
                    <ErrorTooltipAlert />
                </div>
            )}

            {isWarning && (
                <div className="w-full  pt-[8px]">
                    <WarningTooltipAlert />
                </div>
            )} */}
        </div>
    )
}
