import { Outlet, useNavigation } from "react-router-dom"

import { useEffect } from "react"
import toast from "react-hot-toast"
import { useDispatch } from "react-redux"
import { AuthActionCreator } from "../actions/auth.action"
import { DashboardHeader } from "../components/dashboard/dashboardHeader.component"
import AddTrackPackMain from "../components/dashboard/modals/addTrackPackModalSteps/addTrackPackMainModal"
import AdditionalTrackPackMain from "../components/dashboard/modals/additionalTrackPackModals/additionalTrackPackMain.component"
import ArchiveMainModal from "../components/dashboard/modals/archiveModals/archiveMainModal.component"
import { SideNavigation } from "../components/dashboard/sideNavigation.component"

let apiCall: any = null
export const DashboardContainer = () => {
    let navigation = useNavigation()

    const notifyError = (text: string) => toast.error(text)
    const dispatch = useDispatch()

    useEffect(() => {
        if (apiCall) clearTimeout(apiCall)
        apiCall = setTimeout(() => {
            dispatch(
                AuthActionCreator.getAccessLevel(
                    () => {},
                    (res: { message: string }) => {
                        notifyError(res.message)
                    }
                )
            )
        }, 2000)

        return () => {
            clearTimeout(apiCall)
        }
    }, [])

    return (
        <div className="bg-userAccount-custom-gradient pt-[32px] pr-[28px] pb-[24px] pl-[24px] ">
            <DashboardHeader />
            <div className="flex flex-row items-start gap-[20px] w-full " style={{ height: "calc(100vh - 163px)" }}>
                <SideNavigation />
                {navigation.state !== "idle" ? <div className="is-loading ">Loading...</div> : null}

                {/* <div className="bg-white rounded-tl-2xl rounded-bl-2xl flex-1 h-full"> */}
                <div className="h-full" style={{ width: "calc(100% - 80px)" }}>
                    <Outlet />
                </div>
            </div>
            <AddTrackPackMain />
            <AdditionalTrackPackMain />
            <ArchiveMainModal />
        </div>
    )
}
