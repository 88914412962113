import { Fragment, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { PrimaryText } from "../../../elements/primaryText.element"
import { StringDiffElement } from "../../../elements/stringDiff.element"

import { ReactComponent as AlertIcon } from "../../../../assets/svgs/alert.svg"
import { ReactComponent as BulletIcon } from "../../../../assets/svgs/bullets.svg"
import { ReactComponent as Title } from "../../../../assets/svgs/categorises.svg"
import { ReactComponent as CurrencyIcon } from "../../../../assets/svgs/currency-dollar.svg"
import { ReactComponent as CarouselIcon } from "../../../../assets/svgs/more-items.svg"
import { ReactComponent as PencilIcon } from "../../../../assets/svgs/pencil-line.svg"
import { ReactComponent as PlayVideo } from "../../../../assets/svgs/play-video.svg"
import { ReactComponent as PlayIcon } from "../../../../assets/svgs/play.svg"
import { ReactComponent as AsinIcon } from "../../../../assets/svgs/tooltip-asin-icon.svg"
import { ReactComponent as SellerInfo } from "../../../../assets/svgs/tooltip-seller-icon.svg"
import { ReactComponent as StockIcon } from "../../../../assets/svgs/tooltip-stock-icon.svg"
import { Arrow } from "../../../../assets/svgs/tooltipArrow.svg"
import { ReactComponent as MainImageIcon } from "../../../../assets/svgs/view-items.svg"

import dayjs from "dayjs"
import "yet-another-react-lightbox/styles.css"
import { AsinLinkExternalSvg } from "../../../../assets/svgs/AsinExternalLink.svg"
import { VideoUnavailable } from "../../../../assets/svgs/videoUnavailable.svg"
import { handleAmazonProductRedirect } from "../../../../helpers/util.helper"

export const ProductListingData = (props: IProductListingData) => {
    const { selectedProductView, selectedDropdownList } = useSelector(ProductStateSelector)
    const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const selectedObject = selectedDropdownList?.trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id == selectedTrackpackID
    )
    const dispatch = useDispatch()

    const handleProductRedirect = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.stopPropagation()
        handleAmazonProductRedirect(selectedObject?.metadata?.amazon_tld, id)
    }

    const noData = useMemo(() => {
        return props.product.success.every((v) => v.value === false)
    }, [props.product])

    const items = useMemo(() => {
        const views: {
            build: React.ReactElement
            icon: React.ReactElement
            type: string
            time?: String
        }[] = []
        const pd = props.product

        function getUniqueValues(pastArray1: IProductDataVideo[], presentArray2: IProductDataVideo[]) {
            let pastArray = pastArray1 ?? []
            let presentArray = presentArray2 ?? []
            if (!pastArray || !presentArray) {
                return { uniqueInPast: [], uniqueInPresent: [] }
            }
            const uniqueInPast = pastArray?.filter(
                (pastItem) => !presentArray.some((presentItem) => presentItem.video_url === pastItem.video_url)
            )

            const uniqueInPresent = presentArray?.filter(
                (presentItem) => !pastArray.some((pastItem) => pastItem.video_url === presentItem.video_url)
            )
            const maxLength = Math.max(uniqueInPast.length, uniqueInPresent.length)
            const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(null))
            const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(null))
            return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
        }

        function getUniqueValuesCarousel(pastArray: string[] | undefined, presentArray: string[] | undefined) {
            if (!pastArray || !presentArray) {
                return { uniqueInPast: [], uniqueInPresent: [] }
            }

            const uniqueInPast = pastArray.filter((pastItem, index) => pastItem !== presentArray[index])
            const uniqueInPresent = presentArray.filter((presentItem, index) => presentItem !== pastArray[index])

            const maxLength = Math.max(uniqueInPast.length, uniqueInPresent.length)
            const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(null))
            const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(null))
            return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
        }

        const getView = (past: IProductHistoryBase<any>, present: IProductHistoryBase<any>, view: ProductViewType) => {
            let uniqueInPast: any = []
            let uniqueInPresent: any = []

            if (view === "videos") {
                ;({ uniqueInPast, uniqueInPresent } = getUniqueValues(past?.value, present?.value))
            } else if (view === "carousel_images") {
                ;({ uniqueInPast, uniqueInPresent } = getUniqueValuesCarousel(past?.value, present?.value))
            }

            switch (view) {
                case "price":
                    return {
                        build: (
                            <>
                                {past && (
                                    <div className={"flex gap-[8px] items-center"}>
                                        <span className="text-[12px] font-[300] text-[#344054]">${past?.value}</span>
                                        <Arrow color={props.color.primary} />
                                    </div>
                                )}

                                <div className={`${past && "ml-[8px]"}`}>
                                    <span className="text-[12px] font-[300] text-[#344054]">${present?.value}</span>
                                    <br />
                                </div>
                            </>
                        ),
                        icon: <CurrencyIcon />,
                    }
                case "main_image":
                    return {
                        build: (
                            <>
                                {past && (
                                    <div className={"flex gap-[8px] items-center  pb-[5px] cursor-pointer"}>
                                        <img
                                            onClick={() => {
                                                dispatch(
                                                    RuntimeActionCreators.openModal({
                                                        color: props?.color,
                                                        open: true,
                                                        type: "main_image",
                                                        data: props?.product,
                                                    })
                                                )
                                            }}
                                            src={`${past?.value}`}
                                            alt={""}
                                            className={"h-[68px] w-[68px] rounded"}
                                            style={{
                                                border: `1px solid ${props.color.primary}`,
                                            }}
                                        />
                                        <Arrow color={props.color.primary} />
                                    </div>
                                )}

                                <div className="flex flex-col pl-[10px]]  pb-[5px] cursor-pointer">
                                    <img
                                        onClick={() => {
                                            dispatch(
                                                RuntimeActionCreators.openModal({
                                                    color: props?.color,
                                                    open: true,
                                                    type: "main_image",
                                                    data: props?.product,
                                                })
                                            )
                                        }}
                                        src={`${present.value}`}
                                        alt={""}
                                        className={`h-[68px] w-[68px] rounded object-contain bg-gray-800 ${
                                            past && "ml-[8px]"
                                        }`}
                                        style={{
                                            border: `1px solid ${props.color.primary}`,
                                        }}
                                    />
                                </div>
                            </>
                        ),
                        icon: <MainImageIcon />,
                    }
                case "carousel_images":
                    return {
                        build: (
                            <>
                                {
                                    <div className="flex flex-col gap-[10px] py-[19px]">
                                        {typeof props.product.isFirst === "boolean" && props.product.isFirst
                                            ? props.product.carousel_images.map((imageArray, index) => (
                                                  <Fragment key={index}>
                                                      {imageArray.value.map((image, imgIndex) => (
                                                          <div key={imgIndex} className="flex gap-[10px] items-center">
                                                              <div className="relative h-[60px] w-[43px] flex items-center gap-[8px] cursor-pointer">
                                                                  <img
                                                                      src={image}
                                                                      className="rounded aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                                      style={{
                                                                          border: `1px solid ${props.color.primary}`,
                                                                      }}
                                                                      onClick={() => {
                                                                          dispatch(
                                                                              RuntimeActionCreators.openModal({
                                                                                  color: props?.color,
                                                                                  open: true,
                                                                                  type: "carousal",
                                                                                  data: props?.product,
                                                                              })
                                                                          )
                                                                      }}
                                                                      alt=""
                                                                  />
                                                              </div>
                                                          </div>
                                                      ))}
                                                  </Fragment>
                                              ))
                                            : [...Array(Math.max(uniqueInPast?.length, uniqueInPresent?.length))]?.map(
                                                  (_, index) => (
                                                      <Fragment key={index}>
                                                          <div className="flex gap-[10px] items-center">
                                                              {uniqueInPast[index] ? (
                                                                  <div className="relative h-[60px] w-[43px] flex items-center gap-[8px] cursor-pointer">
                                                                      <img
                                                                          src={uniqueInPast[index]}
                                                                          className="rounded aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                                          style={{
                                                                              border: `1px solid ${props.color.primary}`,
                                                                          }}
                                                                          onClick={() => {
                                                                              dispatch(
                                                                                  RuntimeActionCreators.openModal({
                                                                                      color: props?.color,
                                                                                      open: true,
                                                                                      type: "carousal",
                                                                                      data: props?.product,
                                                                                  })
                                                                              )
                                                                          }}
                                                                          alt=""
                                                                      />
                                                                  </div>
                                                              ) : (
                                                                  <div
                                                                      className="relative h-[60px] w-[43px] flex items-center gap-[8px] rounded-[4px]"
                                                                      style={{
                                                                          border: `1px solid ${props.color.primary}`,
                                                                      }}
                                                                  ></div>
                                                              )}

                                                              <Arrow color={props.color.primary} className="mx-auto" />

                                                              {uniqueInPresent[index] ? (
                                                                  <div className="relative h-[60px] w-[43px] flex items-center gap-[8px] cursor-pointer">
                                                                      <img
                                                                          src={uniqueInPresent[index]}
                                                                          className="rounded aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                                          style={{
                                                                              border: `1px solid ${props.color.primary}`,
                                                                          }}
                                                                          onClick={() => {
                                                                              dispatch(
                                                                                  RuntimeActionCreators.openModal({
                                                                                      color: props?.color,
                                                                                      open: true,
                                                                                      type: "carousal",
                                                                                      data: props?.product,
                                                                                  })
                                                                              )
                                                                          }}
                                                                          alt=""
                                                                      />
                                                                  </div>
                                                              ) : (
                                                                  <div
                                                                      className="relative h-[60px] w-[43px] flex items-center gap-[8px] rounded-[4px]"
                                                                      style={{
                                                                          border: `1px solid ${props.color.primary}`,
                                                                      }}
                                                                  ></div>
                                                              )}
                                                          </div>
                                                      </Fragment>
                                                  )
                                              )}
                                    </div>
                                }
                            </>
                        ),
                        icon: <CarouselIcon />,
                    }
                case "title":
                    return {
                        build: (
                            <div className="py-2">
                                {present && past ? (
                                    <>
                                        <StringDiffElement pastValue={past?.value} presentValue={present?.value} />{" "}
                                        <br />
                                    </>
                                ) : (
                                    <PrimaryText weight="light" size="xs">
                                        {present?.value}
                                    </PrimaryText>
                                )}
                            </div>
                        ),
                        icon: <Title />,
                    }
                case "videos":
                    return {
                        build: (
                            <div className={"flex flex-col gap-[10px] py-[19px]"}>
                                {typeof props.product.isFirst === "boolean" && props.product.isFirst
                                    ? props.product.videos.map((videoArray, index) => (
                                          <Fragment key={index}>
                                              {videoArray.value.map((image, imgIndex) => (
                                                  <div key={imgIndex} className="flex gap-[10px] items-center">
                                                      <div className="relative h-[90px] w-[160px] flex items-center gap-[8px] cursor-pointer">
                                                          <img
                                                              src={image.thumbnail_url}
                                                              className="rounded aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                              style={{
                                                                  border: `1px solid ${props.color.primary}`,
                                                              }}
                                                              onClick={() => {
                                                                  dispatch(
                                                                      RuntimeActionCreators.openModal({
                                                                          color: props?.color,
                                                                          open: true,
                                                                          type: "video",
                                                                          data: props?.product,
                                                                      })
                                                                  )
                                                              }}
                                                              alt=""
                                                          />
                                                      </div>
                                                  </div>
                                              ))}
                                          </Fragment>
                                      ))
                                    : [...Array(Math.max(uniqueInPast?.length, uniqueInPresent?.length))]?.map(
                                          (_, index) => (
                                              <Fragment key={index}>
                                                  <div className="flex gap-[10px] items-center">
                                                      {uniqueInPast[index] ? (
                                                          <div className="relative h-[90px] w-[160px] flex items-center gap-[8px] cursor-pointer">
                                                              <img
                                                                  src={uniqueInPast[index]?.thumbnail_url}
                                                                  className="rounded-[3px] aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                                  style={{
                                                                      border: `0.8px solid ${props.color.primary}`,
                                                                  }}
                                                                  onClick={() => {
                                                                      dispatch(
                                                                          RuntimeActionCreators.openModal({
                                                                              color: props?.color,
                                                                              open: true,
                                                                              type: "video",
                                                                              data: props?.product,
                                                                          })
                                                                      )
                                                                  }}
                                                                  alt=""
                                                              />
                                                              <div className="absolute bottom-[2px] right-[3px] cursor-pointer">
                                                                  <PlayVideo />
                                                              </div>
                                                          </div>
                                                      ) : (
                                                          <div
                                                              className="relative h-[90px] w-[160px] flex items-center gap-[8px] rounded-[4px] bg-gray-900 justify-center"
                                                              style={{
                                                                  border: `0.8px solid `,
                                                              }}
                                                          >
                                                              <VideoUnavailable />
                                                          </div>
                                                      )}

                                                      <Arrow color={props.color.primary} className="mx-auto" />

                                                      {uniqueInPresent[index] ? (
                                                          <div className="relative h-[90px] w-[160px] flex items-center gap-[8px] cursor-pointer">
                                                              <img
                                                                  src={uniqueInPresent[index]?.thumbnail_url}
                                                                  className="rounded-[3px] aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                                  style={{
                                                                      border: `0.8px solid ${props.color.primary}`,
                                                                  }}
                                                                  onClick={() => {
                                                                      dispatch(
                                                                          RuntimeActionCreators.openModal({
                                                                              color: props?.color,
                                                                              open: true,
                                                                              type: "video",
                                                                              data: props?.product,
                                                                          })
                                                                      )
                                                                  }}
                                                                  alt=""
                                                              />
                                                              <div className="absolute bottom-[2px] right-[3px] cursor-pointer">
                                                                  <PlayVideo />
                                                              </div>
                                                          </div>
                                                      ) : (
                                                          <div
                                                              className="relative h-[90px] w-[160px] flex items-center gap-[8px] rounded-[4px] bg-gray-900 justify-center"
                                                              style={{
                                                                  border: `0.8px solid `,
                                                              }}
                                                          >
                                                              <VideoUnavailable />
                                                          </div>
                                                      )}
                                                  </div>
                                              </Fragment>
                                          )
                                      )}
                            </div>
                        ),
                        icon: <PlayIcon />,
                    }

                case "bullets":
                    return {
                        build: (() => {
                            const pastLength = past?.value?.length || 0
                            const presentLength = present?.value?.length || 0
                            const itemsToMap = pastLength >= presentLength ? past?.value : present?.value

                            return (
                                <div className="mb-[4px]">
                                    <ul className="">
                                        {itemsToMap?.map((b: string, index: number) => (
                                            <li key={index} className="flex items-start mb-[2px]">
                                                <span className="mr-2 text-[16px]">&#8226;</span>
                                                {past && present ? (
                                                    <StringDiffElement
                                                        pastValue={past?.value[index]}
                                                        presentValue={present?.value[index]}
                                                    />
                                                ) : (
                                                    <>{b}</>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        })(),
                        icon: <BulletIcon />,
                    }
                case "description":
                    return {
                        build: (
                            <div className="py-2">
                                {present && past ? (
                                    <>
                                        <StringDiffElement pastValue={past?.value} presentValue={present?.value} />{" "}
                                        <br />
                                    </>
                                ) : (
                                    <PrimaryText weight="light" size="xs">
                                        {present?.value}
                                    </PrimaryText>
                                )}
                            </div>
                        ),
                        icon: <PencilIcon />,
                    }
                case "actual_asin":
                    return {
                        build: (
                            <>
                                <div className={`flex items-center`}>
                                    <div className="flex gap-[8px] items-center  text-[12px] font-[300] text-[#344054]">
                                        ASIN provided
                                        <div
                                            onClick={(e) => handleProductRedirect(e, present?.value)}
                                            className={`bg-[${props.color.badge}] cursor-pointer px-1 py-[2.5px] rounded-[3px] justify-start items-center inline-flex h-[16px]`}
                                        >
                                            <div className=" items-center gap-[3px] flex">
                                                <PrimaryText
                                                    weight="book"
                                                    size="xs-medium"
                                                    className={`text-[${props.color.hover}] leading-[11px]`}
                                                >
                                                    {present?.value ?? "-"}
                                                </PrimaryText>
                                                {<AsinLinkExternalSvg color={props.color?.hover} />}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </>
                        ),
                        icon: <AsinIcon />,
                    }
                case "seller_info":
                    return {
                        build: (
                            <>
                                {past && (
                                    <div className={"flex gap-[8px] items-center"}>
                                        <span className="text-[12px] font-[300] text-[#344054]">
                                            {past?.value?.name ?? "-"}
                                        </span>
                                        <Arrow color={props.color.primary} />
                                    </div>
                                )}
                                <div className={`${past && "ml-[8px]"}`}>
                                    <span className="text-[12px] font-[300] text-[#344054]">
                                        {present?.value?.name ?? "-"}
                                    </span>
                                    <br />
                                </div>
                            </>
                        ),
                        icon: <SellerInfo />,
                    }
                case "availability":
                    return {
                        build: (
                            <>
                                {past && (
                                    <div className={"flex gap-[8px] items-center"}>
                                        <span className="text-[12px] font-[300] text-[#344054]">
                                            {past?.value ?? "-"}
                                        </span>
                                        <Arrow color={props.color.primary} />
                                    </div>
                                )}
                                <div className={`${past && "ml-[8px]"}`}>
                                    <span className="text-[12px] font-[300] text-[#344054]">
                                        {present?.value ?? "-"}
                                    </span>
                                    <br />
                                </div>
                            </>
                        ),
                        icon: <StockIcon />,
                    }
            }
            return null
        }

        selectedProductView?.selectedViews.forEach((viewItem) => {
            let view: ProductViewType = viewItem
            if (viewItem === "stock") {
                view = "availability"
            }

            // @ts-ignore
            const item = pd[view] as IProductHistoryBase<any>[]

            if (item) {
                for (let index = item.length - 1; index > -1; index--) {
                    const present = item[index]
                    const past = item[index - 1]

                    if (item.length > 1 && past == null) {
                        continue
                    }

                    const createdView = getView(past, present, view)

                    if (createdView)
                        views.push({
                            build: createdView.build,
                            icon: createdView.icon,
                            type: view,
                            time: dayjs(present?.timestamp).format("HH:mm [GMT]"),
                        })
                }
            }
        })

        return views
    }, [props.product, props.color, selectedProductView?.selectedViews, dispatch])

    return (
        <>
            {noData && (
                <div
                    className={`w-full border-b border-[#EEE] bg-[#F9FAFB] justify-start items-start inline-flex py-2`}
                >
                    <div className={"min-w-[108px] pl-[24px]"}>
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            color="text-slate-700"
                            className="text-right pr-[23px]"
                        >
                            {dayjs(props.product.timestamp).format("HH:mm [GMT]")}
                        </PrimaryText>
                    </div>

                    <div className="min-w-[34.5px] flex-col justify-center items-start inline-flex"></div>
                    <>
                        <div className="w-[100px] justify-start items-center inline-flex ">
                            <div
                                className={`h-[20px] w-[20px] p-[2px]  flex justify-center items-center rounded-[4px]`}
                            >
                                <AlertIcon />
                            </div>
                        </div>

                        <div className="w-[396px] mt-[2px] grow overflow-auto justify-self-star items-start inline-flex text-xs font-light text-gray-700">
                            Data unavailable – updates to follow
                        </div>
                    </>
                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="text-center text-slate-700 self-stretch"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-start items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>
                </div>
            )}
            {items.map((view, index) => (
                <div
                    key={index}
                    className={`w-full border-b border-[#EEE] bg-[#F9FAFB] justify-start ${
                        view.type === "bullets" || view.type === "description" || view.type === "main_image"
                            ? "items-start"
                            : "items-center"
                    } inline-flex py-2`}
                >
                    <div className={"min-w-[108px] pl-[24px]"}>
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            color="text-slate-700"
                            className="text-right pr-[23px]"
                        >
                            {view.time}
                        </PrimaryText>
                    </div>

                    <div className="min-w-[37px] flex-col justify-center items-start inline-flex"></div>
                    <>
                        <div className="w-[100px] justify-start items-center inline-flex ">
                            <div
                                className={`h-[28px] p-[4px] flex justify-center items-center rounded-[7px]`}
                                style={{
                                    backgroundColor: `${props.color.accent}`,
                                }}
                            >
                                <div
                                    className={`h-[20px] w-[20px] p-[2px]  flex justify-center items-center rounded-[4px] bg-[${props.color.hover}]`}
                                >
                                    {view.icon}
                                </div>
                            </div>
                        </div>

                        <div className="w-[396px] grow overflow-auto justify-self-star items-start inline-flex text-xs font-light text-gray-700">
                            {view.build}
                        </div>
                    </>
                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="text-center text-slate-700 self-stretch"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-start items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>
                </div>
            ))}
        </>
    )
}
