import { useRef, useState } from "react"

import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../actions/runTime.action"
import { AsinChangeTooltip } from "../../assets/svgs/tooltips/asin-change.svg"
import { CarouselIconTooltip } from "../../assets/svgs/tooltips/carousel.svg"
import { DescriptionIconTooltip } from "../../assets/svgs/tooltips/description.svg"
import { MainImageTooltip } from "../../assets/svgs/tooltips/main-image.svg"
import { PriceIconTooltip } from "../../assets/svgs/tooltips/price-icon.svg"
import { SellerInfoIconTooltip } from "../../assets/svgs/tooltips/seller-info.svg"
import { StockTooltipIcon } from "../../assets/svgs/tooltips/stock.svg"
import { TitleIconTooltip } from "../../assets/svgs/tooltips/title.svg"
import { BulletsIconTooltip } from "../../assets/svgs/tooltips/tooltip-bullets.svg"
import { VideoIconTooltip } from "../../assets/svgs/tooltips/video.svg"
import { UtilHelper } from "../../helpers/util.helper"
import "../../styles/tooltipIcons.component.css"
import { PrimaryText } from "../elements/primaryText.element"

export const TooltipIcon = (props: ITooltipIcons) => {
    const [hoveredPrice, setHoveredIcon] = useState("")
    const dispatch = useDispatch()

    const { type, color, product, checked, id } = props
    const ref = useRef(null)
    return (
        <>
            <div
                className={`relative flex  items-center  rounded-[4px] p-[2px] 
                hover:transition-all justify-center ${type} ${`${type}-${props.iconNumber}`}
                ${
                    type && product && product[type] && product[type]!.length
                        ? `${product[type]![0]?.timestamp}-${type}`
                        : ""
                }
                ${
                    type && product && product[type] && product[type]?.length && product[type]!.length > 2
                        ? " justify-between col-span-2"
                        : "justify-center"
                }
                ${
                    type === "stock" &&
                    product &&
                    product["availability"] &&
                    product["availability"]?.length &&
                    product["availability"]!.length > 2
                        ? " justify-between col-span-2"
                        : "justify-center"
                }
                ${checked ? `bg-[${color.primary}]` : `bg-[${color.hover}]`}
                `}
                onClick={(e) => {
                    if (product && product[type]) {
                        if (type === "actual_asin") {
                            if (!UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[])) {
                                return
                            }
                        }
                    }
                }}
            >
                <div ref={ref} className="flex ">
                    {(() => {
                        switch (type) {
                            case "price":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("price")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,
                                                data: product,
                                                type: "price",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.price ? "price-tooltip" : ""}
                                            onClick={() =>
                                                product?.price
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "price-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px]">
                                                <PriceIconTooltip
                                                    stroke={
                                                        product?.price
                                                            ? hoveredPrice === "price" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.price ? "cursor-pointer" : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="text-[7.5px] mt-[1px] ml-[1px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "main_image":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("main_image")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-id={product?.main_image ? "main_image-tooltip" : ""}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "main_image",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            onClick={() =>
                                                product?.main_image
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "main_image-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <MainImageTooltip
                                                    stroke={
                                                        product?.main_image
                                                            ? hoveredPrice === "main_image" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.main_image ? "cursor-pointer" : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "carousel_images":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("carousel_images")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-id={product?.carousel_images ? "carousel_images-tooltip" : ""}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "carousel_images",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            onClick={() =>
                                                product?.carousel_images
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "carousel_images-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <CarouselIconTooltip
                                                    stroke={
                                                        product?.carousel_images
                                                            ? hoveredPrice === "carousel_images" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.carousel_images
                                                            ? "cursor-pointer"
                                                            : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "title":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("title")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "title",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.title ? "title-tooltip" : ""}
                                            onClick={() =>
                                                product?.title
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "title-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <TitleIconTooltip
                                                    stroke={
                                                        product?.title
                                                            ? hoveredPrice === "title" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.title ? "cursor-pointer" : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "bullets":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("bullets")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "bullets",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.bullets ? "bullets-tooltip" : ""}
                                            onClick={() =>
                                                product?.bullets
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "bullets-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px]">
                                                <BulletsIconTooltip
                                                    stroke={
                                                        product?.bullets
                                                            ? hoveredPrice === "bullets" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.bullets ? "cursor-pointer" : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "description":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("description")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,
                                                data: product,
                                                type: "description",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.description ? "description-tooltip" : ""}
                                            onClick={() =>
                                                product?.description
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "description-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <DescriptionIconTooltip
                                                    stroke={
                                                        product?.description
                                                            ? hoveredPrice === "description" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.description ? "cursor-pointer" : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "videos":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("videos")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "videos",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.videos ? "videos-tooltip" : ""}
                                            onClick={() =>
                                                product?.videos
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "videos-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <VideoIconTooltip
                                                    stroke={
                                                        product?.videos
                                                            ? hoveredPrice === "videos" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.videos ? "cursor-pointer" : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "seller_info":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("seller_info")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "seller_info",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.seller_info ? "seller_info-tooltip" : ""}
                                            onClick={() =>
                                                product?.seller_info
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "seller_info-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <SellerInfoIconTooltip
                                                    stroke={
                                                        product?.seller_info
                                                            ? hoveredPrice === "seller_info" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.seller_info ? "cursor-pointer" : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "stock":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("stock")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "stock",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={product?.availability ? "stock-tooltip" : ""}
                                            onClick={() =>
                                                product?.availability
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "stock-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <StockTooltipIcon
                                                    stroke={
                                                        product?.availability
                                                            ? hoveredPrice === "stock" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        product?.availability ? "cursor-pointer" : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product["availability"] &&
                                                    product["availability"]?.length &&
                                                    product["availability"]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product["availability"]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            case "actual_asin":
                                return (
                                    <>
                                        <div
                                            onMouseEnter={() => setHoveredIcon("actual_asin")}
                                            onMouseLeave={() => setHoveredIcon("")}
                                            data-tooltip-content={JSON.stringify({
                                                color: color,

                                                data: product,
                                                type: "actual_asin",
                                                id: id,
                                                totalIcons: props.totalIcons,
                                            })}
                                            data-tooltip-id={
                                                UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[])
                                                    ? "actual_asin-tooltip"
                                                    : ""
                                            }
                                            onClick={() =>
                                                UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[])
                                                    ? dispatch(
                                                          RuntimeActionCreators.openPopup(
                                                              "actual_asin-tooltip" + id + product?.timestamp
                                                          )
                                                      )
                                                    : null
                                            }
                                        >
                                            <div className="flex items-center gap-[3px] ">
                                                <AsinChangeTooltip
                                                    stroke={
                                                        UtilHelper.areIdsSame(
                                                            product?.actual_asin as unknown as IActualAsin[]
                                                        )
                                                            ? hoveredPrice === "actual_asin" && !checked
                                                                ? props.color.light
                                                                : "white"
                                                            : props.color.disabled
                                                    }
                                                    className={
                                                        UtilHelper.areIdsSame(
                                                            product?.actual_asin as unknown as IActualAsin[]
                                                        )
                                                            ? "cursor-pointer"
                                                            : "cursor-not-allowed "
                                                    }
                                                />
                                                {type &&
                                                    product &&
                                                    product[type] &&
                                                    product[type]?.length &&
                                                    product[type]!.length > 2 && (
                                                        <div
                                                            className={`bg-[${props.color.light}] text-[${props.color.accent}]  pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                                                        >
                                                            <PrimaryText
                                                                weight={"light"}
                                                                className="mt-[1px] ml-[1px] text-[7.54px]"
                                                            >
                                                                {product && product[type]!.length - 1}
                                                            </PrimaryText>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )
                            default:
                                return null
                        }
                    })()}
                </div>
            </div>
        </>
    )
}
