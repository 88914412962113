export class RuntimeStateClass implements IAppRuntimeState {
    openLightBox?: ILightBox
    loading = [] as { [key: string]: boolean }[]
    modal?: IModalOptions
    popups = ""
    trackPackModal?: IModalTrackPackOptions
    newAsinTrackPack?: IAsinTrackPack
    newAsinAdditional?: IAdditionalProps
    archiveState?: IArchiveProps
}
