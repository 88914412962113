import { useContainsChanges } from "../../hooks/containsChanges.hook"

export const CustomDotSvg = (props: ICustomSvgDot) => {
    const payload: ISelectedProduct = {
        timestamp: props.payload!.timestamp,
        color: props.color,
        product: props.payload![props.id!],
        id: props.id!,
    }
    const handleMouseOver = () => {
        if (payload)
            props.customMoveClick!({
                cx: props.cx,
                cy: props.cy,
                payload,
            })
    }

    const isFirst = props.initialTimestamp && props.initialTimestamp === props.payload!.timestamp

    const containsChange = useContainsChanges(payload.product)
    if (containsChange) {
        return (
            <>
                {/* {isFirst && payload.product.warnings?.includes("actual_asin") ? (
                    <>
                        <svg
                            id={props.dataKey!.split("[")[0]}
                            x={props.cx! - 8}
                            y={props.cy! - 8}
                            className="cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="12"
                            viewBox="0 0 14 12"
                            fill="none"
                        >
                            <path
                                d="M0.9141 10.0327L6.12306 1.35283C6.41094 0.873117 6.55489 0.633261 6.74268 0.552702C6.9065 0.482433 7.0935 0.482433 7.25732 0.552702C7.44511 0.633261 7.58906 0.873116 7.87694 1.35283L13.0859 10.0327C13.3748 10.5141 13.5193 10.7548 13.4979 10.9524C13.4793 11.1247 13.3857 11.2813 13.2405 11.3832C13.0739 11.5 12.7856 11.5 12.209 11.5H1.79104C1.21439 11.5 0.926068 11.5 0.759532 11.3832C0.614276 11.2813 0.520691 11.1247 0.502068 10.9524C0.480717 10.7548 0.625178 10.5141 0.9141 10.0327Z"
                                fill="#000"
                            />
                            <path
                                d="M7 4.47384V7.52869M7 9.3616H7.00633M6.12306 1.35283L0.9141 10.0327C0.625178 10.5141 0.480717 10.7548 0.502068 10.9524C0.520691 11.1247 0.614276 11.2813 0.759532 11.3832C0.926068 11.5 1.21439 11.5 1.79104 11.5H12.209C12.7856 11.5 13.0739 11.5 13.2405 11.3832C13.3857 11.2813 13.4793 11.1247 13.4979 10.9524C13.5193 10.7548 13.3748 10.5141 13.0859 10.0327L7.87694 1.35283C7.58906 0.873116 7.44511 0.633261 7.25732 0.552702C7.0935 0.482433 6.9065 0.482433 6.74268 0.552702C6.55489 0.633261 6.41094 0.873117 6.12306 1.35283Z"
                                stroke="#F79009"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </>
                ) : ( */}
                <svg
                    id={props.dataKey!.split("[")[0]}
                    x={props.cx! - 6}
                    y={props.cy! - 6}
                    width={24}
                    height={24}
                    // fill={props.color?.primary}
                    viewBox="0 0 200 200"
                    onClick={handleMouseOver}
                    className={`cursor-pointer hover:${props?.color?.primary}`}
                >
                    <circle
                        cx="46"
                        cy="46"
                        r="25"
                        strokeWidth="12"
                        fill={isFirst ? "white" : props?.color?.primary}
                        stroke={isFirst ? props?.color?.primary : "white"}
                    />
                </svg>
                {/* )} */}
            </>
        )
        // return UtilHelper.areIdsSame(props?.payload![props.dataKey!.split("[")[0]]?.actual_asin  as unknown as IActualAsin[]) ? (
        //     <svg
        //         id={props.dataKey!.split("[")[0]}
        //         x={props.cx! - 10}
        //         y={props.cy! - 10}
        //         width={'16px'}
        //         height={'16px'}
        //         onClick={handleMouseOver}
        //         className={`cursor-pointer hover:${props?.color?.primary}`}
        //         viewBox="0 0 16 16"
        //         fill="none"
        //         xmlns="http://www.w3.org/2000/svg"
        //     >
        //         <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#ECFDFF"
        //         stroke={props?.color?.primary}
        //         />
        //         <path
        //             d="M8 6.89006V9.11177M8 10.4448H8.00438M7.39289 4.62024L3.78668 10.9328C3.58666 11.283 3.48665 11.458 3.50143 11.6017C3.51432 11.7271 3.57911 11.8409 3.67968 11.915C3.79497 12 3.99458 12 4.3938 12H11.6062C12.0054 12 12.205 12 12.3203 11.915C12.4209 11.8409 12.4857 11.7271 12.4986 11.6017C12.5133 11.458 12.4133 11.283 12.2133 10.9328L8.60711 4.62024C8.40781 4.27136 8.30816 4.09692 8.17814 4.03833C8.06473 3.98722 7.93527 3.98722 7.82186 4.03833C7.69184 4.09692 7.59219 4.27136 7.39289 4.62024Z"

        //             stroke={props?.color?.primary}
        //             stroke-linecap="round"
        //             stroke-linejoin="round"
        //         />
        //     </svg>
        // ) : (
        //     <svg
        //         id={props.dataKey!.split("[")[0]}
        //         x={props.cx! - 10}
        //         y={props.cy! - 10}
        //         width={40}
        //         height={40}
        //         // fill={props.color?.primary}
        //         viewBox="0 0 200 200"
        //         onClick={handleMouseOver}
        //         className={`cursor-pointer hover:${props?.color?.primary}`}
        //     >
        //         <circle
        //             cx="50"
        //             cy="50"
        //             r="25"
        //             strokeWidth="5"
        //             fill={
        //                 props.initialTimestamp && props.initialTimestamp === props.payload!.timestamp
        //                     ? "white"
        //                     : props?.color?.primary
        //             }
        //             stroke={props?.color?.primary}
        //         />
        //     </svg>
        // )
    }
    return null
}
