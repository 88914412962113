import { ReactComponent as DescriptionIcon } from "../../../../assets/svgs/align-items.svg"
import { ReactComponent as TitleIcon } from "../../../../assets/svgs/categorises.svg"
import { ReactComponent as CurrencyIcon } from "../../../../assets/svgs/currency-dollar.svg"
import { ReactComponent as CarouselIcon } from "../../../../assets/svgs/more-items.svg"
import { ReactComponent as PencilIcon } from "../../../../assets/svgs/pencil-line.svg"
import { ReactComponent as PlayIcon } from "../../../../assets/svgs/play.svg"
import { ReactComponent as AsinIcon } from "../../../../assets/svgs/tooltip-asin-icon.svg"
import { ReactComponent as SellerInfo } from "../../../../assets/svgs/tooltip-seller-icon.svg"
import { ReactComponent as StockIcon } from "../../../../assets/svgs/tooltip-stock-icon.svg"
import { ReactComponent as MainImageIcon } from "../../../../assets/svgs/view-items.svg"

import "../../../../styles/tooltipIcons.component.css"
import { PrimaryText } from "../../../elements/primaryText.element"

export const LogIcons = (props: ILogIcons) => {
    const { type, product } = props

    return (
        <>
            <div
                className={`flex  items-center  rounded-[3px] 
                justify-center ${type}
                ${
                    type && product && product[type] && product[type]?.length && product[type]!.length > 2
                        ? " justify-between"
                        : "justify-center"
                }
                ${
                    type &&
                    product &&
                    product["availability"] &&
                    product["availability"]?.length &&
                    product["availability"]!.length > 2
                        ? " justify-between "
                        : "justify-center"
                }
                `}
            >
                <div className="flex">
                    <LogIconWrapper color={props.color} type={type} product={product}>
                        {(() => {
                            switch (type) {
                                case "price":
                                    return <CurrencyIcon />
                                case "main_image":
                                    return <MainImageIcon />
                                case "carousel_images":
                                    return <CarouselIcon />
                                case "title":
                                    return <TitleIcon />
                                case "bullets":
                                    return <DescriptionIcon />
                                case "description":
                                    return <PencilIcon />
                                case "videos":
                                    return <PlayIcon />
                                case "seller_info":
                                    return <SellerInfo />
                                case "stock":
                                    return <StockIcon />
                                case "actual_asin":
                                    return <AsinIcon />

                                default:
                                    return null
                            }
                        })()}
                    </LogIconWrapper>
                </div>
            </div>
        </>
    )
}

const LogIconWrapper = (props: {
    children: any
    type: ProductViewType
    color: TAsinProductColor
    product?: IProductData
}) => {
    const { type, product } = props

    return (
        <>
            <div className={`flex items-center gap-[6px] p-[2px] rounded-[4px] bg-[${props.color.hover}]`}>
                {props.children}

                {type && product && product[type] && product[type]?.length && product[type]!.length > 2 && (
                    <div
                        className={`bg-[${props.color.badge}] text-[${props.color.accent}] pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                    >
                        <PrimaryText weight={"light"} className="leading-normal text-[7.54px]">
                            {product && product[type]!.length - 1}
                        </PrimaryText>
                    </div>
                )}
            </div>
        </>
    )
}
