import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"

import { PriceIconTooltip } from "../../../assets/svgs/tooltips/popups/popup-price-icon.svg"
import { PrimaryText } from "../../elements/primaryText.element"

import { UtilHelper } from "../../../helpers/util.helper"

export const PriceInfo = (props: ITooltipData) => {
    const { t } = useTranslation("tooltip", {
        keyPrefix: "priceInfo",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const { active, history, setActive, total } = useProductHistory(props.data, "price")
    const currencySymbol = UtilHelper.getCurrencySymbol(props?.data?.currency?.[0]?.value)
    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )
    return (
        <>
            <div className={`p-[12px] w-[216px]`}>
                <InnerTooltipHeader
                    title={t("price")}
                    linkText={tCommon("Go to ASIN")}
                    timestamp={tCommon("timestamp", {
                        timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                    icon={<PriceIconTooltip stroke={props.color?.hover} />}
                    active={active}
                    total={total}
                    setActive={setActive}
                    color={props.color?.light}
                    id={props.id}
                />

                <div className="mt-[32px] flex gap-[8px] justify-between">
                    {history.past && (
                        <>
                            <div className="w-full p-[8px] pt-[10px] bg-slate-600 rounded-lg">
                                <PrimaryText
                                    size="xs"
                                    weight="light"
                                    color={`text-[${props.color?.light}]`}
                                    className="text-[12px] leading-[10px]"
                                >
                                    {tCommon("was")}
                                </PrimaryText>

                                <PrimaryText size="sm-medium" className="mt-[6px] text-white leading-tight">
                                    {currencySymbol}
                                    {history.past?.value}
                                </PrimaryText>
                            </div>
                        </>
                    )}
                    <div className={`w-full p-[8px] pt-[10px] bg-slate-600 rounded-lg`}>
                        <PrimaryText
                            size="xs"
                            weight="light"
                            color={`text-[${props.color?.light}]`}
                            className="text-[12px] leading-[10px]"
                        >
                            {tCommon("now")}
                        </PrimaryText>

                        <PrimaryText size="sm-medium" weight="light" className=" mt-[6px] text-white leading-tight">
                            {currencySymbol}
                            {history.present?.value}
                        </PrimaryText>
                    </div>
                </div>
                {/* {isSuccessWarning && (
                    <div className="w-full  pt-[8px]">
                        <ErrorTooltipAlert />
                    </div>
                )}

                {isWarning && (
                    <div className="w-full  pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
        </>
    )
}
