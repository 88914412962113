import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ExpandIcon } from "../../../assets/svgs/expandIcon.svg"
import { MainImageTooltip } from "../../../assets/svgs/tooltips/popups/main-image.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"

export const MainImage = (props: ITooltipData) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("tooltip", {
        keyPrefix: "mainImage",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )

    const { active, history, setActive, total } = useProductHistory(props.data, "main_image")
    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openModal({
                color: props?.color,
                open: true,
                type: "main_image",
                data: props?.data,
            })
        )
    }

    return (
        <>
            <div className={`w-[216px] p-[12px] bg-[#1D2939] rounded-2xl`}>
                <InnerTooltipHeader
                    title={t("Main Image")}
                    linkText={tCommon("Go to ASIN")}
                    timestamp={tCommon("timestamp", {
                        timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                    active={active}
                    total={total}
                    setActive={setActive}
                    color={props.color?.light}
                    id={props.id}
                    icon={<MainImageTooltip stroke={props.color?.hover} />}
                />

                <div className="flex items-center justify-between w-full gap-[8px] mt-[32px]">
                    {history.past && (
                        <>
                            <div className="w-[92px] p-2 bg-slate-600 rounded-lg flex-col inline-flex">
                                <div className="flex items-center justify-between w-full mb-[8px] px-[2px] pt-[2px]">
                                    <PrimaryText
                                        size={"xs-small"}
                                        weight={"light"}
                                        className="text-[12px] leading-[10px] tracking-[0.96px] "
                                        color={`text-[${props.color?.light}]`}
                                    >
                                        {tCommon("was")}
                                    </PrimaryText>
                                    <ExpandIcon
                                        className="cursor-pointer"
                                        onClick={handleOpen}
                                        color={props?.color?.light}
                                    />
                                </div>

                                <div className="w-[76px] h-[76px]">
                                    <img
                                        className="w-full h-full rounded border object-contain cursor-pointer bg-gray-800"
                                        style={{ borderColor: props.color?.primary }}
                                        src={history.past.value}
                                        onClick={handleOpen}
                                        alt="mainIMage"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div
                        className={`w-[92px] ${
                            !history.past ? "" : ""
                        } p-2 bg-slate-600 rounded-lg flex-col inline-flex`}
                    >
                        <div className="flex items-center justify-between w-full mb-[8px] px-[2px] pt-[2px]">
                            <PrimaryText
                                size={"xs-small"}
                                weight={"light"}
                                className=" text-[12px] leading-[10px] tracking-[0.96px]"
                                color={`text-[${props.color?.light}]`}
                            >
                                {tCommon("now")}
                            </PrimaryText>
                            <ExpandIcon className="cursor-pointer" onClick={handleOpen} color={props?.color?.light} />
                        </div>
                        <div className="w-[76px] h-[76px]">
                            <img
                                className="w-full h-full rounded border object-contain cursor-pointer bg-gray-800 "
                                style={{ borderColor: props.color?.primary }}
                                src={history.present?.value || ""}
                                onClick={handleOpen}
                                alt="mainIMage"
                            />
                        </div>
                    </div>
                </div>
                {/* {isSuccessWarning && (
                    <div className="w-full  pt-[8px]">
                        <ErrorTooltipAlert />
                    </div>
                )}

                {isWarning && (
                    <div className="w-full  pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
        </>
    )
}
