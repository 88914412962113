import { PrimaryText } from "../../elements/primaryText.element"

const CardCustomTooltipContent = ({ id, data }: ICardTooltipProps) => {
    return (
        <>
            {id === "tracking-success-element" ? (
                <div className=" bg-[#DCFAE6]">
                    <div className="flex items-center gap-[2px]">
                        <PrimaryText
                            weight="medium"
                            size="xs-medium"
                            className=" leading-[14px] text-[#067647] whitespace-nowrap"
                        >
                            ASIN
                        </PrimaryText>
                        <PrimaryText weight="light" size="xs-medium" className="text-[#067647] leading-[14px]">
                            tracking
                        </PrimaryText>
                    </div>
                </div>
            ) : id === "tracking-off-element" ? (
                <div className=" bg-[#FEF0C7]  px-[4px]  flex flex-col gap-[4px]">
                    {data
                        ?.filter(
                            (v) =>
                                v === "bsr_large" ||
                                v === "bsr_small" ||
                                v === "rating" ||
                                v === "ratings_count" ||
                                v === "actual_asin"
                        )
                        .map((val: WarningType, index) => {
                            let warningString = ""
                            switch (val) {
                                case "bsr_large":
                                    warningString = "BSR L Cat."
                                    break
                                case "bsr_small":
                                    warningString = "BSR S Cat."
                                    break
                                case "rating":
                                    warningString = "Review Score"
                                    break
                                case "ratings_count":
                                    warningString = "Ratings Count"
                                    break
                                case "actual_asin":
                                    warningString = "Different ASIN"
                                    break
                            }
                            return (
                                <div key={index} className="flex items-center gap-[2px]">
                                    <PrimaryText
                                        weight="medium"
                                        size="xs-medium"
                                        className="text-[#B54708] leading-[14px] whitespace-nowrap"
                                    >
                                        {warningString}
                                    </PrimaryText>
                                    <PrimaryText
                                        weight="light"
                                        size="xs-medium"
                                        className="text-[#B54708] leading-[14px] whitespace-nowrap"
                                    >
                                        {warningString === "Different ASIN" ? "received" : "unavailable"}
                                    </PrimaryText>
                                </div>
                            )
                        })}
                </div>
            ) : (
                <div className={`bg-[#FEE4E2]`}>
                    <div className="flex items-center gap-[2px]">
                        <PrimaryText
                            weight="medium"
                            size="xs-medium"
                            className=" text-[#B42318] leading-[14px] whitespace-nowrap"
                        >
                            Data
                        </PrimaryText>
                        <PrimaryText
                            weight="light"
                            size="xs-medium"
                            className="text-[#B42318] leading-[14px] whitespace-nowrap"
                        >
                            unavailable
                        </PrimaryText>
                    </div>
                </div>
            )}
        </>
    )
}

export default CardCustomTooltipContent
