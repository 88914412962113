import AustraliaImg from "../assets/images/aus.jpg"
import BelgiumImg from "../assets/images/belgium.png"
import BrazilImg from "../assets/images/brazil.jpg"
import CanadaImg from "../assets/images/canada.jpg"
import ChineImg from "../assets/images/china.jpg"
import EgyptImg from "../assets/images/egypt.jpg"
import FranceImg from "../assets/images/france.jpg"
import GermanyImg from "../assets/images/germany.png"
import trackpackImg from "../assets/images/image11.png"
import IndiaImg from "../assets/images/india.png"
import ItalyImg from "../assets/images/italy.png"
import JapanImg from "../assets/images/japan.png"
import MexicoImg from "../assets/images/maxico.jpg"
import NetherlandImg from "../assets/images/netherland.jpg"
import PolandImg from "../assets/images/poleand.png"
import SaudiaImg from "../assets/images/saudia.png"
import SpainImg from "../assets/images/spanin.jpg"
import SwedenImg from "../assets/images/sweden.jpg"
import TurkeyImg from "../assets/images/turkey.jpg"
import UaeImg from "../assets/images/uae.jpg"
import UkImg from "../assets/images/uk.png"

import SindaporeImg from "../assets/images/singapore.jpg"
import AsinChangeIcon from "../assets/svgs/filters/AsinChangeFilterIcon.svg"
import BulletIcon from "../assets/svgs/filters/BulletFilterIcon.svg"
import CarouselIcon from "../assets/svgs/filters/CarouselFilterIcon.svg"
import DescriptionIcon from "../assets/svgs/filters/DescriptionFilterIcon.svg"
import MainImageIcon from "../assets/svgs/filters/MainImageFilterIcon.svg"
import PriceIcon from "../assets/svgs/filters/PriceFilterIcon.svg"
import StockIcon from "../assets/svgs/filters/StockFilterIcon.svg"
import TitleIcon from "../assets/svgs/filters/TitleFilterIcon.svg"
import SellerIcon from "../assets/svgs/filters/TopSellerFilterIcon.svg"
import VideoIcon from "../assets/svgs/filters/VideoFilterIcon.svg"
import UsaIcon from "../assets/svgs/usaIcon.png"

export const DashboardBoardConfig = {
    graphConfig: {
        productDifferentiateItems: [
            { value: "price", label: "Price", component: PriceIcon, width: "32px" },
            { value: "stock", label: "Stock", component: StockIcon, width: "35px" },
            { value: "title", label: "Title", component: TitleIcon, width: "30px" },
            { value: "bullets", label: "Bullets", component: BulletIcon, width: "40px" },
            { value: "description", label: "Description", component: DescriptionIcon, width: "58px" },
            { value: "main_image", label: "Main Image", component: MainImageIcon, width: "59px" },
            { value: "carousel_images", label: "Carousel", component: CarouselIcon, width: "48px" },
            { value: "videos", label: "Video", component: VideoIcon, width: "34px" },
            { value: "actual_asin", label: "ASIN", component: AsinChangeIcon, width: "32px" },
            { value: "seller_info", label: "Seller Info", component: SellerIcon, width: "54px" },
        ],
        productResults: [
            { value: "bsr_large", label: "BSR L Cat", LogViewLabel: "BSR Large Category" },
            { value: "bsr_small", label: "BSR S Cat", LogViewLabel: "BSR Small Category" },
            { value: "ratings_count", label: "Ratings Count", LogViewLabel: "Ratings Count" },
            { value: "rating", label: "Review Score", LogViewLabel: "Review Score" },
        ],
        productRange: [
            { value: "daily", label: "Daily" },
            { value: "weekly", label: "Weekly" },
            { value: "monthly", label: "Monthly" },
        ],
        productRangeUpdated: [
            { value: 7, label: "1<span className='text-[9px]'>W</span>", labelName: "1 Week", width: "42px" },
            { value: 14, label: "2<span className='text-[9px]'>W</span>", labelName: "2 Week", width: "46px" },
            { value: 30, label: "1<span className='text-[9px]'>M</span>", labelName: "1 Month", width: "48px" },
            { value: 180, label: "6<span className='text-[9px]'>M</span>", labelName: "6 Months", width: "50px" },
            { value: 0, label: "A<span className='text-[9px]'>LL</span>", labelName: "All", width: "28px" },
        ],
    },
}

export const img_url = "https://amaizing.sfo3.digitaloceanspaces.com/public/images/"

export const TrackPacks = [
    {
        title: "Men’s Suit Jackets",
        userASINs: 1,
        competitorASINs: 7,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5RVASV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5CRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "60 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
    {
        title: "Men’s T-Shirts",
        userASINs: 1,
        competitorASINs: 4,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 5,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },

            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],

        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5CRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "60 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
]

export const rowData = [
    {
        ASINs: {
            asinNo: "B08N5WRWNV",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "Ah",
        "Date Added": "2023-06-01",
        time_deletion: "120 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWNZ",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JH",
        "Date Added": "2023-06-02",
        time_deletion: "90 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWNY",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "AL",
        "Date Added": "2023-06-03",
        time_deletion: "80 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WDWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "BO",
        "Date Added": "2023-06-04",
        time_deletion: "110 day",
    },
    {
        ASINs: {
            asinNo: "B08N5XRWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JD",
        "Date Added": "2023-06-01",
        time_deletion: "40 day",
    },
    {
        ASINs: {
            asinNo: "B08N5CRWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "60 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WFWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "190 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWxx",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "20 day",
    },
]

export const countriesList = [
    {
        value: "USA",
        label: "USA",
        flagUrl: UsaIcon,
    },
    {
        value: "australia",
        label: "Australia",
        flagUrl: AustraliaImg,
    },
    {
        value: "belgium",
        label: "Belgium",
        flagUrl: BelgiumImg,
    },
    {
        value: "brazil",
        label: "Brazil",
        flagUrl: BrazilImg,
    },
    {
        value: "canada",
        label: "Canada",
        flagUrl: CanadaImg,
    },

    {
        value: "china",
        label: "China",
        flagUrl: ChineImg,
    },
    {
        value: "egypt",
        label: "Egypt",
        flagUrl: EgyptImg,
    },
    {
        value: "france",
        label: "France",
        flagUrl: FranceImg,
    },
    {
        value: "germany",
        label: "Germany",
        flagUrl: GermanyImg,
    },
    {
        value: "india",
        label: "India",
        flagUrl: IndiaImg,
    },
    {
        value: "italy",
        label: "Italy",
        flagUrl: ItalyImg,
    },

    {
        value: "japan",
        label: "Japan",
        flagUrl: JapanImg,
    },
    {
        value: "mexico",
        label: "Mexico",
        flagUrl: MexicoImg,
    },
    {
        value: "netherland",
        label: "Netherland",
        flagUrl: NetherlandImg,
    },
    {
        value: "poland",
        label: "Poland",
        flagUrl: PolandImg,
    },
    {
        value: "saudia_arabia",
        label: "Saudia Arabia",
        flagUrl: SaudiaImg,
    },
    {
        value: "singapore",
        label: "Singapore",
        flagUrl: SindaporeImg,
    },
    {
        value: "spain",
        label: "Spain",
        flagUrl: SpainImg,
    },
    {
        value: "sweden",
        label: "Sweden",
        flagUrl: SwedenImg,
    },
    {
        value: "turkey",
        label: "Turkey",
        flagUrl: TurkeyImg,
    },
    {
        value: "uae",
        label: "UAE",
        flagUrl: UaeImg,
    },
    {
        value: "united_kindom",
        label: "United Kindom",
        flagUrl: UkImg,
    },
]
