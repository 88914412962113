import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import Slider from "../../elements/slider.component"

import { ExpandIcon } from "../../../assets/svgs/expandIcon.svg"
import { VideoIconTooltip } from "../../../assets/svgs/tooltips/popups/video.svg"
import { VideoUnavailable } from "../../../assets/svgs/videoUnavailable.svg"
import { XsVideoIcon } from "../../../assets/svgs/xsVideoIcon.svg"

export const TooltipVideo = (props: ITooltipData) => {
    const { active, history, setActive, total } = useProductHistory(props.data, "videos")
    const dispatch = useDispatch()
    const { t } = useTranslation("tooltip", {
        keyPrefix: "videos",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })
    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )

    const handleOpen = (index: number) => {
        dispatch(
            RuntimeActionCreators.openModal({
                color: props?.color,
                open: true,
                type: "video",
                data: props?.data,
                index: index,
            })
        )
    }

    function getUniqueValues(
        pastArray: IProductDataVideo[] | undefined,
        presentArray: IProductDataVideo[] | undefined
    ) {
        if (!pastArray || !presentArray) {
            return { uniqueInPast: [], uniqueInPresent: [] }
        }
        const uniqueInPast = pastArray?.filter(
            (pastItem: IProductDataVideo) =>
                !presentArray.some((presentItem) => presentItem?.video_url === pastItem?.video_url)
        )
        const uniqueInPresent = presentArray?.filter(
            (presentItem: IProductDataVideo) =>
                !pastArray.some((pastItem) => pastItem?.video_url === presentItem?.video_url)
        )

        if (props.data.videos?.length > 1) {
            const maxLength = Math.max(uniqueInPast.length, uniqueInPresent.length)
            const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(null))
            const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(null))
            return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
        }
        return { uniqueInPast, uniqueInPresent }
    }

    const showUnavailablePast = props?.data?.videos?.length && !history.past && props?.data?.isFirst !== true
    const pastValuesPlaceholder = showUnavailablePast
        ? Array(props?.data?.videos?.length).fill({ video_url: null, thumbnail_url: null })
        : history.past?.value
    const { uniqueInPast, uniqueInPresent } = getUniqueValues(pastValuesPlaceholder, history.present?.value)

    return (
        <>
            <div className="w-[232px] py-[12px] bg-[#1D2939] rounded-2xl">
                <div className="px-[12px] mb-[32px]">
                    <InnerTooltipHeader
                        title={t("video")}
                        linkText={tCommon("Go to ASIN")}
                        timestamp={tCommon("timestamp", {
                            timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                        })}
                        active={active}
                        total={total}
                        setActive={setActive}
                        color={props.color?.light}
                        id={props.id}
                        icon={<VideoIconTooltip stroke={props.color?.hover} />}
                    />
                </div>
                <Slider color={props.color}>
                    {uniqueInPast.length > 0 && uniqueInPresent.length > 0 ? (
                        <>
                            {[...Array(Math.max(uniqueInPast.length, uniqueInPresent.length))].map(
                                (presentLink: IProductDataVideo, presentIndex: number) => {
                                    const presetLinkValues = uniqueInPresent[presentIndex]
                                    const pastLink = uniqueInPast[presentIndex]
                                    return (
                                        <div
                                            key={presentIndex}
                                            className="embla__slide min-w-[116px] p-2 bg-slate-600 rounded-lg video-slider-flex mr-[8px]"
                                        >
                                            <div className="flex items-center justify-between w-full mb-[8px] px-[2px] pt-[2px]">
                                                <PrimaryText
                                                    size={"xs-small"}
                                                    weight={"book"}
                                                    className="text-[12px] leading-[10px] tracking-[0.96px]"
                                                    color={`text-[${props.color?.light}]`}
                                                >
                                                    {tCommon("was")}
                                                </PrimaryText>
                                                <ExpandIcon
                                                    className="cursor-pointer"
                                                    onClick={() => handleOpen(presentIndex)}
                                                    color={props.color?.light}
                                                />
                                            </div>
                                            {pastLink?.thumbnail_url ? (
                                                <img
                                                    key={presentIndex}
                                                    className="object-contain chrome:w-[100px] bg-gray-900 safari:w-[100px] h-[56px] rounded-[4px] border border-gray-400 select-none cursor-pointer"
                                                    style={{ borderColor: props.color?.primary }}
                                                    src={pastLink?.thumbnail_url}
                                                    onClick={() => handleOpen(presentIndex)}
                                                    alt=""
                                                />
                                            ) : (
                                                <div
                                                    className="object-contain chrome:w-[100px] safari:w-[100px] h-[56px] rounded-[4px] flex items-center justify-center border border-gray-400 select-none cursor-pointer bg-gray-900"
                                                    style={{
                                                        border: `0.8px solid`,
                                                    }}
                                                >
                                                    <VideoUnavailable />
                                                </div>
                                            )}

                                            <div className="mt-[14px]">
                                                <PrimaryText
                                                    size={"xs-small"}
                                                    weight={"book"}
                                                    className="self-stretch text-[12px] leading-[10px] tracking-[0.96px] mb-[8px] px-[2px] pt-[2px]"
                                                    color={`text-[${props.color?.light}]`}
                                                >
                                                    {tCommon("now")}
                                                </PrimaryText>
                                                <div className="relative">
                                                    {presetLinkValues?.thumbnail_url ? (
                                                        <>
                                                            <img
                                                                key={presentIndex}
                                                                className="object-contain chrome:w-[100px] safari:w-[100px]  border border-gray-400 select-none cursor-pointer rounded-[4px] h-[56px] bg-gray-900"
                                                                // style={{ borderColor: props.color?.primary }}
                                                                src={presetLinkValues?.thumbnail_url}
                                                                onClick={() => handleOpen(presentIndex)}
                                                                alt=""
                                                            />
                                                            <div className="cursor-pointer absolute w-[12px] h-[12px] left-[0px] bottom-[10px]">
                                                                <XsVideoIcon className="" />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div
                                                            className="object-contain chrome:w-[100px] safari:w-[100px] h-[56px] flex items-center justify-center rounded-[4px] border border-gray-400 select-none cursor-pointer bg-gray-900"
                                                            style={
                                                                {
                                                                    // border: `1px solid #98A2B3}`,
                                                                }
                                                            }
                                                        >
                                                            <VideoUnavailable />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className=" w-3 h-3 bg-slate-700  rounded-[3px] mt-[12px] justify-center items-center flex">
                                                <div className=" text-center pt-[2px] text-white text-[8px] font-bold font-['F37 Bolton'] leading-[6px]">
                                                    {presentIndex + 1}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </>
                    ) : (
                        <>
                            {props.data.videos.length === 1 &&
                                history.present?.value?.map((presentLink: IProductDataVideo, index: number) => (
                                    <div data-cy={index} className="min-w-[116px] p-2 bg-slate-600 rounded-lg mr-[8px]">
                                        <div className="flex items-center justify-between w-full mb-[8px] px-[2px] pt-[2px]">
                                            <PrimaryText
                                                size={"xs-small"}
                                                weight={"book"}
                                                className=" text-[12px] leading-[10px] tracking-[0.96px] px-[2px] pt-[2px]"
                                                color={`text-[${props.color?.light}]`}
                                            >
                                                {tCommon("now")}
                                            </PrimaryText>
                                            <ExpandIcon
                                                className="cursor-pointer"
                                                onClick={() => handleOpen(index)}
                                                color={props.color?.light}
                                            />
                                        </div>
                                        <div className="relative">
                                            <img
                                                className="w-full h-[56px] rounded border  cursor-pointer select-none object-contain bg-gray-600"
                                                style={{ borderColor: props.color?.primary }}
                                                src={presentLink?.thumbnail_url}
                                                alt=""
                                                onClick={() => handleOpen(index)}
                                            />
                                            <div className="cursor-pointer w-[12px] h-[12px] absolute left-[0px] bottom-[10px]">
                                                <XsVideoIcon className="" />
                                            </div>
                                        </div>

                                        <div className=" w-3 h-3 mt-[16px] bg-slate-700 rounded-[3px] flex-col justify-center items-center flex">
                                            <PrimaryText
                                                size="xs-small"
                                                weight="bold"
                                                className=" text-center pt-[2px] text-white leading-[6px]"
                                            >
                                                {index + 1}
                                            </PrimaryText>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </Slider>
                {/* {isSuccessWarning && (
                    <div className="w-full px-[12px] pb-[12px]">
                        <ErrorTooltipAlert />
                    </div>
                )}
                {isWarning && (
                    <div className="w-full px-[12px] pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
        </>
    )
}
