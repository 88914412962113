const apiUrl = process.env.REACT_APP_API_URL
export const ASIN_AMAZON_LINK_BASE = "http://amazon.com/dp/"
export const API_ROUTES = {
    GET_USER_ACCOUNT_DETAIL: `${apiUrl}/guests/guest_id`,
    GET_ACCESS_LEVEL: `${apiUrl}/users/me`,
    GET_ALL_TRACKPACKS_DROPDOWN_LIST: `${apiUrl}/products/extended_trackpacks`,
    //Authentication
    GET_TOKEN: `${apiUrl}/token/obtain-pair`,
    GET_REFRESH_TOKEN: `${apiUrl}/token/refresh`,
    GUEST_USER: `${apiUrl}/guests`,
    UPDATE_ASIN_USER: `${apiUrl}/guest/guest_id/asins`,
    UPDATE_GUEST_USER_ACCOUNT: `$${apiUrl}/guest/guest_id`,
    VERIFY_ALPHA_EMAIL: `${apiUrl}/users/alpha-email-check`,
    ALPHA_USER_REGISTER: `${apiUrl}/users/alpha-user-forms`,
    GET_ALL_USERS: `${apiUrl}/users/users`,
    CREATE_TRACKPACK: `${apiUrl}/products/trackpacks`,
    CREATE_ADDITIONAL_TRACKPACK: `${apiUrl}/products/add_asins`,
    SIGNUP_USER: `${apiUrl}/users/signup`,
    RECOVER_PASSWORD: `${apiUrl}/users/recover-password`,
    VERIFY_PASSWORD_EMAIL: `${apiUrl}/users/verify-password-recovery-email`,
    VERIFY_EMAIL: `${apiUrl}/users/verify-signup-email`,
    RESEND_VERIFY_EMAIL: `${apiUrl}/users/resend-signup-verification-email`,
    UPDATE_TRACKPACK_NAME: `${apiUrl}/products/trackpacks`,
    ARCHIVE_TRACKPACK: `${apiUrl}/products/archive_trackpack`,
    ARCHIVE_ASINS: `${apiUrl}/products/archive_asins`,
    GET_PRODUCTS_DATA: `${apiUrl}/products/trackpacks`,
}

export class ROUTES_CONFIG {
    static home = "/"
    static dashboard = "/dashboard"

    static auth = "/auth"
    static login = this.auth + "/login"
    static forgotPassword = this.auth + "/forgot-password"
    static resetPassword = this.auth + "/reset-password"
    static welcome = this.auth + "/welcome"

    static verifySignup = "/signup"
    static verifyResetPassword = "/reset-password"

    static marketing = "/onboarding"
    static signup = this.marketing + "/signup"
    static authSignup = this.auth + "/signup"
    static checkEmail = this.verifySignup + "/verify-email"
    static resetPasswordCheckEmail = this.verifyResetPassword + "/verify-email"

    static verifiedEmail = this.auth + "/email-verified"
    // static freeTrialChat = this.marketing + "/trial-chat"
    static emailChat = this.marketing + "/email-chat"
    static trackingChatForm = this.marketing + "/tracking-chat-form"
    static searchCompetitorChat = this.marketing + "/search-competitor"
    static asinTrackerChat = this.marketing + "/asin-tracker-chat"
    static listingManageForm = this.marketing + "/listing-manage-form"
    static jobRoleForm = this.marketing + "/job-role-form"
    static signUpForm = this.marketing + "/signup-form"

    static privacyPolicy = "/privacy-policy"

    static trackpackCentral = this.dashboard + "/trackpack-central"
    static userAccounts = this.dashboard + "/user-accounts"
}
